var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.program.loading)?_c('loading'):_c('div',[_c('h5',{staticClass:"font-weight-bold my-0 mb-1"},[_vm._v("Jetzt LIVE im TV")]),_c('div',{staticClass:"my-2"},[_c('button',{staticClass:"btn mr-2",class:{
                    'btn-primary': _vm.selected == 'pNow',
                    'btn-outline-primary': _vm.selected != 'pNow',
                },on:{"click":function($event){_vm.selected = 'pNow'}}},[_vm._v(" Jetzt ")]),_c('button',{staticClass:"btn mr-2",class:{
                    'btn-primary': _vm.selected == 'pAfter',
                    'btn-outline-primary': _vm.selected != 'pAfter',
                },on:{"click":function($event){_vm.selected = 'pAfter'}}},[_vm._v(" Danach ")])]),_c('b-alert',{attrs:{"show":_vm.hTodayLive === null,"dismissible":"","variant":"primary"},on:{"dismissed":function($event){return _vm.handleClick()}}},[_vm._v(" Tippe auf "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}}),_vm._v(" um den Livestream zu starten. ")],1),(_vm.selected == 'pNow')?_c('div',_vm._l((_vm.pNow),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleClick()}}},[(
                        index == 0 &&
                        _vm.eligableForJoyn &&
                        _vm.everyX &&
                        Object.keys(_vm.randomJoynItem).length > 0
                    )?_c('joyn-item',{attrs:{"item":_vm.randomJoynItem,"source":"now_live"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"badge badge-primary text-white ml-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'medal']}}),_vm._v(" FILMTIPP ")],1)]},proxy:true}],null,true)}):_vm._e(),_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_LIVE,"item":item,"c":item.c}})],1)}),0):_vm._e(),(_vm.selected == 'pAfter')?_c('div',_vm._l((_vm.pAfter),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleClick()}}},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_LIVE,"item":item,"c":item.c}})],1)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }