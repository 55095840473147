import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { EventBus } from '@/event-bus'
export default {
    mixins: [validationMixin],
    data: function () {
        return {
            version: localStorage.getItem('app_version') || '-',
            loading: true,
            notification: {
                muted: false,
                distance: 5,
            },
            mute: {
                from: '00:00',
                to: '06:00',
                labels: {
                    de: {
                        labelHours: 'Stunden',
                        labelMinutes: 'Minuten',
                        labelSeconds: 'Sekunden',
                        labelIncrement: 'Erhöhen',
                        labelDecrement: 'Verringern',
                        labelSelected: 'Ausgewählte Zeit',
                        labelNoTimeSelected: 'Keine Zeit ausgewählt',
                        labelCloseButton: 'Schließen',
                    },
                },
            },
            modals: {
                about: {
                    show: false,
                    data: [],
                },
                report: {
                    success: null,
                    error: null,
                    show: false,
                    form: {
                        email: '',
                        message: '',
                    },
                },
                suggestion: {
                    success: null,
                    error: null,
                    show: false,
                    form: {
                        email: '',
                        message: '',
                    },
                },
                payment: {
                    show: false,
                },
                imprint: {
                    show: false,
                },
                privacy: {
                    show: false,
                },
                copyright: {
                    show: false,
                },
            },
        }
    },
    computed: {
        subscription() {
            return this.$store.state.user.subscription
        },
        movieNotifications: {
            get: function () {
                return this.$store.state.user.settings.movie_notifications
            },
            set: function (newValue) {
                this.loading = true
                this.$store.state.user.settings.movie_notifications = newValue
                this.updateSettings()
            },
        },
        screenMirroring: {
            get: function () {
                return !this.nativeBridge('screenMirroringOn')
            },
            set: function (newValue) {
                this.nativeBridge('setScreenMirroring', !newValue)
            },
        },
        fullscreenMode: {
            get: function () {
                return this.nativeBridge('bannerFullscreenOn')
            },
            set: function (newValue) {
                this.nativeBridge('setBannerFullscreen', newValue)
            },
        },
        wifi_only: {
            get: function () {
                return this.$store.state.user.settings.wifi_only
            },
            set: function (newValue) {
                this.loading = true
                this.nativeBridge('onlyWifi', newValue)
                this.$store.state.user.settings.wifi_only = newValue
                this.updateSettings()
            },
        },
    },
    watch: {},
    validations: {
        modals: {
            report: {
                form: {
                    email: {
                        email,
                    },
                },
            },
            suggestion: {
                form: {
                    email: {
                        email,
                    },
                },
            },
        },
    },
    methods: {
        fetchSettings() {
            this.$store.dispatch('user/fetchSettings').catch((e) => {
                this.handleAxiosError(e)
            })
        },
        updateSettings() {
            this.$store
                .dispatch('user/updateSettings')
                .catch((e) => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                    this.nativeBridge('showToast', 'Einstellungen gespeichert')
                })
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.modals.report.form[name]
            return !$error
        },
        validateState2(name) {
            const { $dirty, $error } = this.$v.modals.suggestion.form[name]
            return !$error
        },
        focusInput() {
            this.$refs.focusThis.focus()
        },
        focusInput2() {
            this.$refs.focusThis2.focus()
        },
        send() {
            alert('sending...')
        },
        openPremiumModal() {
            EventBus.$emit('onPremiumRequested')
        },
        onSubmitReport(e) {
            e.preventDefault()
            this.$v.modals.report.form.$touch()
            if (this.$v.modals.report.form.$anyError) {
                return
            }
            this.modals.report.success = false
            this.modals.report.error = false
            const token = this.$store.state.auth.token
            axios
                .post('/report', this.modals.report.form, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .catch((e) => {
                    this.handleAxiosError(e)
                    this.modals.report.error = true
                })
                .then((response) => {
                    this.modals.report.success = true
                    this.modals.suggestion.form = {
                        email: '',
                        message: '',
                    }
                })
        },
        onSubmitSuggestion(e) {
            e.preventDefault()
            this.$v.modals.suggestion.form.$touch()
            if (this.$v.modals.suggestion.form.$anyError) {
                return
            }
            this.modals.suggestion.success = false
            this.modals.suggestion.error = false
            const token = this.$store.state.auth.token
            axios
                .post('/suggestion', this.modals.suggestion.form, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .catch((e) => {
                    this.handleAxiosError(e)
                    this.modals.suggestion.error = true
                })
                .then((response) => {
                    this.modals.suggestion.success = true
                    this.modals.suggestion.form = {
                        email: '',
                        message: '',
                    }
                })
        },
        openDsgvoConfig() {
            this.nativeBridge('openDsgvoConfig')
        },
    },
    mounted() {
        this.fetchSettings()
    },
}
