export default {
    data: function () {
        return {
        }
    },
    methods: {
        openUrl(url) {
            this.nativeBridge('openUrl', {
                url,
                mode: 'chrome',
            })
        },
        cc(val) {
            this.$router.push({
                name: val,
                query: {
                    history_back: 1,
                },
            })
        }
    }
}
