import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import JoynItem from '../components/JoynItem'
import Loading from '../components/Loading'
import axios from "axios";
import { EventBus } from '@/event-bus'

export default {
    components: {
        Broadcast,
        JoynItem,
        Loading,
    },
    data: function () {
        return {
            version: localStorage.getItem('app_version') || '-',
            hTodayLive: null || localStorage.getItem('hTodayLive'),
            selected: 'pNow',
            modals: {
                payment: {
                    show: false,
                }
            },
            banner: {
                rating: {
                    show: false || !localStorage.getItem('banner-rating-hide'),
                    action: {
                        a: false,
                        b: false,
                    },
                },
                payment: {
                    show: false || !localStorage.getItem('banner-payment-hide') && localStorage.getItem('banner-rating-hide'),
                    action: {
                        a: false,
                    },
                },
            },
            randomJoynItem: {}
        }
    },
    computed: {
        ...mapGetters('program', {
            pNow: 'pNow',
        }),
        ...mapGetters('program', {
            pAfter: 'pAfter',
        }),
        ...mapGetters('program', {
            p2015: 'p2015',
        }),
        ...mapGetters('program', {
            p2200: 'p2200',
        }),
        subscription() {
            return this.$store.state.user.subscription
        },
        userCreatedAt() {
            return dayjs(this.$store.state.user.created_at)
        },
        eligableForJoyn() {
            return this.version >= 49;
        },
        everyX() {
            return true;
        }
    },
    methods: {
        bannerAdClose() {
            this.banner.payment.show = false
            this.banner.payment.action.b = true
            localStorage.setItem('banner-payment-hide', true)
        },
        bannerAdActionA() {
            this.banner.payment.action.a = false
            EventBus.$emit('onPremiumRequested')
        },
        bannerRatingActionA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = true
        },
        bannerRatingActionB() {
            this.banner.rating.show = false
            this.banner.rating.action.b = true
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionClose() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionAA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
            this.nativeBridge("openUrl", { url: "market://details?id=com.live.tv.kostenlos", mode: "chrome" });
        },

        handleClick() {
            this.hTodayLive = 1
            localStorage.setItem('hTodayLive', this.hTodayLive)
        },
        fetchProgram() {
            this.$store
                .dispatch('program/fetch', {
                    data: {
                        station: this.stationQuery,
                        date: this.dateQuery,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchSeries() {
            this.$store
                .dispatch('series/fetch', {
                    v: this.$router.currentRoute.query.v
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        fetchUserStations() {
            this.$store
                .dispatch('user/fetchStations')
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {
        this.fetchUserStations()
        this.fetchSeries()
        this.fetchProgram()

        axios.get("/joyn/random-movie", {
            headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
        }).then(response => {
            this.randomJoynItem = response.data
        }).catch(console.error)

        axios
            .post('/activity/router/live', {}, {
                headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
            })
            .catch(e => {
                this.handleAxiosError(e)
            })
    }
}