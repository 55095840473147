<template>
    <div>
        <loading v-if="$store.state.program.loading" />
        <div v-else>
            <h5 class="font-weight-bold my-0 mb-1">Heute Abend im TV</h5>
            <div class="my-2">
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2015',
                        'btn-outline-primary': selected != 'p2015',
                    }"
                    @click="selected = 'p2015'"
                    v-if="
                        dayjs().isBefore(
                            dayjs().startOf('day').add(22, 'hours')
                        ) &&
                        dayjs().isAfter(dayjs().startOf('day').add(5, 'hours'))
                    "
                >
                    ab 20:15
                </button>
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2200',
                        'btn-outline-primary': selected != 'p2200',
                    }"
                    @click="selected = 'p2200'"
                    v-if="
                        dayjs().isBefore(
                            dayjs().startOf('day').add(23, 'hours')
                        ) &&
                        dayjs().isAfter(dayjs().startOf('day').add(5, 'hours'))
                    "
                >
                    ab 22:00
                </button>
                <button
                    class="btn"
                    :class="{
                        'btn-primary': selected == 'p2300',
                        'btn-outline-primary': selected != 'p2300',
                    }"
                    @click="selected = 'p2300'"
                >
                    ab 23:00
                </button>
            </div>
            <joyn-item
                :item="randomJoynItem"
                source="2015"
                v-if="
                    eligableForJoyn &&
                    everyX &&
                    Object.keys(randomJoynItem).length > 0
                "
            >
                <template #header>
                    <span class="badge badge-primary text-white ml-1">
                        <font-awesome-icon :icon="['fas', 'medal']" />
                        FILMTIPP
                    </span>
                </template>
            </joyn-item>
            <div
                v-for="(item, index) in rows"
                :key="index"
                @click="handleClick()"
            >
                <broadcast
                    :layout="BROADCAST_LAYOUT_LIVE"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import P2015 from '../../../views/P2015'
export default {
    ...P2015,
}
</script>
