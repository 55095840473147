<template>
    <div>
        <h5 class="page-title mb-0">Filme kostenlos streamen</h5>
        <loading v-if="loading" />
        <div v-else>
            <b-form-select
                class="mt-2 mb-1"
                v-model="filter.selected"
                :options="filter.options"
                @change="onChangeFilter"
            ></b-form-select>
            <div v-for="(item, index) in items" :key="'a-' + index">
                <media-library-item :item="item" :layout="3" :index="index" />
            </div>
            <loading class="mt-2" v-if="appendLoading" />
        </div>
    </div>
</template>
<script>
import MediaLibraryMovies from '../../../views/MediaLibraryMovies'
export default {
    ...MediaLibraryMovies,
}
</script>
