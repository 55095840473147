<template>
    <div>
        <div class="bg-white shadow-sm p-3 rating" v-if="banner.rating.show">
            <h1 class="h4 font-weight-bold mb-2">Gefällt dir die App?</h1>
            <div class="d-flex">
                <button
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    style="width: 120px"
                    @click="bannerRatingActionA()"
                >
                    Ja
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    style="width: 120px"
                    @click="bannerRatingActionB()"
                >
                    Nein
                </button>
            </div>
        </div>

        <div
            class="alert alert-dismissible shadow-sm bg-white my-3"
            v-if="banner.rating.action.a"
        >
            <button
                type="button"
                class="close"
                @click="bannerRatingActionClose()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <h1 class="h4 font-weight-bold mb-2">
                <font-awesome-icon
                    :icon="['fas', 'star-half-alt']"
                    class="mr-1"
                />
                Bitte bewerte uns
            </h1>
            <p class="mb-1">
                Wir würden uns sehr freuen wenn du dir kurz Zeit nimmst und uns
                5 Sterne in Google Play geben würdest.
            </p>
            <button
                type="button"
                class="btn btn-block btn-outline-primary"
                @click="bannerRatingActionAALive()"
            >
                Jetzt Bewertung abgeben
            </button>
        </div>
            <div
                v-if="banner.payment.show"
                class="alert alert-dismissible shadow-sm bg-white my-3"
            >
                <button type="button" class="close" @click="bannerAdClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="h5 font-weight-bold mb-2">
                    <font-awesome-icon :icon="['fas', 'ad']" class="mr-1" />
                    LIVE TV Pro
                </h1>
                <div @click="bannerAdActionA()">
                    <p class="mb-1">
                        Hol dir jetzt LIVE TV Pro <strong>ohne Werbung</strong>. Einmalige Zahlung.
                        Dauerhaft werbefrei.
                    </p>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="bannerAdActionA()"
                    >
                        Mehr Infos
                    </button>
                </div>
            </div>        
            <div
                class="alert alert-dismissible shadow-sm bg-white my-3"
                v-if="banner.mixxtv.show"
            >
                <button type="button" class="close" @click="bannerMixxTvClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="h5 font-weight-bold mb-2">
                    <font-awesome-icon :icon="['fas', 'ad']" class="mr-1" />
                    Neue Version verfügbar
                </h1>
                <div @click="bannerMixxTvActionA()">
                    <p class="mb-1">
                        Teste jetzt kostenlos unsere neue App  <strong>MIXXTV (LIVE TV 2.0)</strong>
                    </p>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="bannerMixxTvActionA()"
                    >
                        Jetzt testen
                    </button>
                </div>
            </div>               
        <div class="position-relative alert alert-dismissible bg-white shadow p-3 my-2" v-if="banner.megasim.show && false">
            <button type="button" class="close" @click="bannerTariffActionClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <h1 class="h5 font-weight-bold mb-0">
                JOYN TEST
            </h1>
            <p class="mb-0">
                <span class="text-primary font-weight-bold">Free unlimited Smart</span> - <strong>für 19,99€</strong><br />
                <ul class="fa-ul mb-0 ml-4">
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>unbegrenztes Datenvolumen mit 10 Mbit/s</li>
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>Telefon- & SMS-Flat inklusive</li>
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>EU-Nutzung inklusive</li>
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>monatlich kündbar</li>
                </ul>
                <button type="button" class="btn btn-primary mt-2 mr-2" @click="bannerTariffActionA()">Angebot ansehen</button>
            </p>
            <div style="position: absolute; bottom: 0; right: 0" class="px-2 py-1 text-dark">
                <font-awesome-icon :icon="['fas', 'ad']" class="h3 mb-0" />
            </div>
        </div>
        <b-overlay :show="nowLoading" rounded="sm">
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('live')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'play-circle']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Jetzt im TV</h1>
                    <span class="text-muted mb-0">Was läuft gerade?</span>
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
        </b-overlay>
        <b-overlay :show="tonightLoading" rounded="sm">
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('p2015')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'magic']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Heute Abend</h1>
                    <span class="text-muted mb-0">Was läuft ab 20:15?</span>
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
        </b-overlay>
        <b-overlay :show="topLoading" rounded="sm">
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('joyn')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'star']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Kostenlose Filme</h1>
                    <span class="text-muted mb-0"
                        >Ganze Filme kostenlos anschauen</span
                    >
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
        </b-overlay>
        <b-overlay rounded="sm" v-if="false">
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('wm')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'futbol']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">EM 2024</h1>
                    <span class="text-muted mb-0"
                        >
                        Fußball-Europameisterschaft
                        </span
                    >
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
        </b-overlay>
        <div class="position-relative alert alert-dismissible bg-white shadow p-3 my-2" v-if="banner.megasim.show && false">
            <button type="button" class="close" @click="bannerTariffActionClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <h1 class="h5 font-weight-bold mb-0">
                Unbegrenztes Datenvolumen
            </h1>
            <p class="mb-0">
                <img @click="bannerTariffActionA()" src="https://handyhaus.de/uploads/anbieter_megasim_63669c4eec.png" width="150" /><br />
                <span class="text-primary font-weight-bold">Free unlimited Smart</span> - <strong>für 19,99€</strong><br />
                <ul class="fa-ul mb-0 ml-4">
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>unbegrenztes Datenvolumen mit 10 Mbit/s</li>
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>Telefon- & SMS-Flat inklusive</li>
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>EU-Nutzung inklusive</li>
                    <li><span class="fa-li"><font-awesome-icon :icon="['fas', 'check']" /></span>monatlich kündbar</li>
                </ul>
                <button type="button" class="btn btn-primary mt-2 mr-2" @click="bannerTariffActionA()">Angebot ansehen</button>
            </p>
            <div style="position: absolute; bottom: 0; right: 0" class="px-2 py-1 text-dark">
                <font-awesome-icon :icon="['fas', 'ad']" class="h3 mb-0" />
            </div>
        </div>
        <div v-if="todaysBroadcasts.length">
            <h1 class="h5 font-weight-bold mt-4 mb-2" @click="cc('bookmarks')">
                Mein Programm
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="$store.state.series.broadcasts.loading" />
            <div v-else>
                <broadcast
                    v-for="(item, index) in todaysBroadcasts"
                    :key="'a-' + index"
                    :layout="BROADCAST_LAYOUT_BOOKMARK"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
        <div class="pb-2" v-if="joynWatchedItems.length">
            <h1
                class="h5 font-weight-bold mt-4 mb-2"
            >
                Zuletzt angesehenen
            </h1>
            <div class="slider">
                <div class="positiion-relative" v-for="item of joynWatchedItems" :key="item.slug">
                    <div
                        class="d-flex bg-white m-1" style="width: 85px"
                        @click="handleJoynItemClick(item)"
                    >
                            <image-item
                                :index="0"
                                :source="item.image_url"
                                class="w-100"
                                height="125px"
                            />
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="pb-2">
            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="cc('joyn')"
            >
                Filme kostenlos streamen
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <b-alert
                :show="hMediaLibraryMovies === null"
                dismissible
                variant="primary"
                @dismissed="hideHint()"
            >
                Alle Filme aus der Mediathek können zu
                <strong>jeder Zeit</strong> abgespielt werden. Es kommen jeden
                Tag neue Filme hinzu.
            </b-alert>
            <loading v-if="$store.state.medialibrary.movies.loading" />
            <div v-else class="slider mb-4" @click="hideHint">
                <div  class="positiion-relative" v-for="(item) of joynItems" :key="item.slug">
                    <div
                        class="h-100 bg-white mr-2" style="width: 180px"
                        @click="handleJoynItemClick(item)"
                    >
                        <image-item
                            :index="0"
                            :source="item.image_url"
                            class="w-100 mb-2"
                            height="265px"
                        />
                        <div class="p-1">
                            <div class="small d-flex align-items-center">
                                <div style="white-space: nowrap">
                                    <font-awesome-icon
                                        :icon="['fas', 'star']"
                                        v-for="n in Math.ceil(
                                            item.vote_average / 2
                                        )"
                                        :key="'aacc' + n"
                                    />
                                    <font-awesome-icon
                                        :icon="['far', 'star']"
                                        v-for="n in 5 -
                                        Math.ceil(item.vote_average / 2)"
                                        :key="'aaacc' + n"
                                    />
                                </div>
                                <span
                                    class="ml-1 text-muted"
                                    style="
                                        overflow: hidden;
                                        white-space: nowrap;
                                    "
                                    >{{ item.vote_count }} Stimmen</span
                                >
                            </div>
                            <strong>{{
                                item.title.length > 40
                                    ? item.title.substring(0, 40) + '...'
                                    : item.title
                            }}</strong>
                        </div>
                    </div>
                </div>
                <div
                style="height: 330px"
                    class="
                        shadow-sm
                        bg-white
                        m-1
                        p-3
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    @click="cc('joyn')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>
        </div>
        <b-modal
            v-model="modals.worldtv.show"
            no-close-on-backdrop
            header-bg-variant="primary"
            centered
            modal-class="modal-fullscreen"
            body-class=""
            ok-only
        >
            <template #modal-header>
                <div
                    class="
                        text-white text-center
                        rounded
                        w-100
                        p-1
                        h5
                        mb-0
                        font-weight-bold
                    "
                >
                    {{ $t('worldtv.modal.title') }}
                </div>
            </template>
            <div style="font-size: 110%">
                <p v-html="$t('worldtv.modal.description')"></p>
            </div>
            <template #modal-footer>
                <button
                    class="btn btn-block btn-primary"
                    @click="
                        worldTvOk(
                            $t('worldtv.modal.playstore_id'),
                            $i18n.locale
                        )
                    "
                >
                    {{ $t('worldtv.modal.ok') }}
                </button>
                <button
                    class="btn btn-block text-dark btn-link"
                    @click="worldTvCancel()"
                >
                    {{ $t('worldtv.modal.cancel') }}
                </button>
            </template>
        </b-modal>
        <joyn-modal
            :item="selectedMovie"
            source="now_medialibrary"
            v-model="selectedMovieModal"
            title="🔥 Kostenlos anschauen"
            :hide-mute-message="true"
        />
    </div>
</template>
<script>
import Today from '../../../views/Today'
export default {
    ...Today,
}
</script>
