var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.program.loading)?_c('loading'):_c('div',[_c('h5',{staticClass:"font-weight-bold my-0 mb-1"},[_vm._v("Heute Abend im TV")]),_c('div',{staticClass:"my-2"},[(
                    _vm.dayjs().isBefore(
                        _vm.dayjs().startOf('day').add(22, 'hours')
                    ) &&
                    _vm.dayjs().isAfter(_vm.dayjs().startOf('day').add(5, 'hours'))
                )?_c('button',{staticClass:"btn mr-2",class:{
                    'btn-primary': _vm.selected == 'p2015',
                    'btn-outline-primary': _vm.selected != 'p2015',
                },on:{"click":function($event){_vm.selected = 'p2015'}}},[_vm._v(" ab 20:15 ")]):_vm._e(),(
                    _vm.dayjs().isBefore(
                        _vm.dayjs().startOf('day').add(23, 'hours')
                    ) &&
                    _vm.dayjs().isAfter(_vm.dayjs().startOf('day').add(5, 'hours'))
                )?_c('button',{staticClass:"btn mr-2",class:{
                    'btn-primary': _vm.selected == 'p2200',
                    'btn-outline-primary': _vm.selected != 'p2200',
                },on:{"click":function($event){_vm.selected = 'p2200'}}},[_vm._v(" ab 22:00 ")]):_vm._e(),_c('button',{staticClass:"btn",class:{
                    'btn-primary': _vm.selected == 'p2300',
                    'btn-outline-primary': _vm.selected != 'p2300',
                },on:{"click":function($event){_vm.selected = 'p2300'}}},[_vm._v(" ab 23:00 ")])]),(
                _vm.eligableForJoyn &&
                _vm.everyX &&
                Object.keys(_vm.randomJoynItem).length > 0
            )?_c('joyn-item',{attrs:{"item":_vm.randomJoynItem,"source":"2015"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"badge badge-primary text-white ml-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'medal']}}),_vm._v(" FILMTIPP ")],1)]},proxy:true}],null,false,1735225548)}):_vm._e(),_vm._l((_vm.rows),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleClick()}}},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_LIVE,"item":item,"c":item.c}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }