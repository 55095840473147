<template>
    <div>
        <loading v-if="$store.state.series.recommendations.loading" />
        <div v-else>
            <h5 class="font-weight-bold my-0 mb-1">TOP Filme im TV</h5>
            <div class="days d-flex py-2">
                <div
                    v-for="(item, index) in days"
                    :key="'date-' + index"
                    class="shadow-sm py-1 px-3 mx-1 bg-white text-center"
                    :class="{
                        'text-primary': item.isToday,
                        'border border-2 border-primary':
                            joynSelected === false &&
                            (item.format('YYYY-MM-DD') == day ||
                                (day === null && index == 0)),
                    }"
                    @click="navigateDate(item)"
                >
                    <small class="text-muted">
                        <span
                            :class="{
                                'text-danger':
                                    item.format('dd') == 'Sa' ||
                                    item.format('dd') == 'So',
                            }"
                            >{{ item.format('dd') }}</span
                        >
                    </small>
                    <br />
                    <h4 class="m-0 font-weight-bolder">
                        {{ item.format('D') }}
                    </h4>
                    <small
                        v-if="false && item.isSame(new Date(), 'day')"
                        style="margin-top: -2px; display: block"
                        >heute</small
                    >
                </div>
            </div>
            <div
                v-for="(item, index) in future"
                :key="'f-' + index"
                @click="hideHint()"
            >
                <broadcast
                    v-for="(item2, index2) in item.items"
                    :key="'f2' + index2"
                    :layout="BROADCAST_LAYOUT_RECOMMENDATION_FULL"
                    :item="item2"
                    :c="item2.c"
                    imdb
                />
            </div>
        </div>
    </div>
</template>
<script>
import Recommendations from '../../../views/Recommendations'
export default {
    ...Recommendations,
}
</script>
