<template>
    <div>
        <div
            class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
            @click="cc('search-wm')"
        >
            <div style="width: 45px">
                <font-awesome-icon
                    class="h2 mb-0 text-primary"
                    :icon="['fas', 'play-circle']"
                />
            </div>
            <div>
                <h1 class="h4 font-weight-bold mb-0">TV Übertragungen</h1>
                <span class="text-muted mb-0"
                    >Alle Spiele im LIVE TV anschauen</span
                >
            </div>
            <div class="pl-4 flex-fill text-right">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>

        <div
            class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
            @click="
                openUrl('https://www.kicker.de/europameisterschaft/spieltag')
            "
        >
            <div style="width: 45px">
                <font-awesome-icon
                    class="h2 mb-0 text-primary"
                    :icon="['fas', 'list']"
                />
            </div>
            <div>
                <h1 class="h4 font-weight-bold mb-0">Spielplan</h1>
                <span class="text-muted mb-0"
                    >Alle Begegnungen auf einem Blick</span
                >
            </div>
            <div class="pl-4 flex-fill text-right">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>
        <div
            class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
            @click="
                openUrl('https://www.kicker.de/europameisterschaft/tabelle')
            "
        >
            <div style="width: 45px">
                <font-awesome-icon
                    class="h2 mb-0 text-primary"
                    :icon="['fas', 'table']"
                />
            </div>
            <div>
                <h1 class="h4 font-weight-bold mb-0">Tabelle</h1>
                <span class="text-muted mb-0">Übersicht der Spielstände</span>
            </div>
            <div class="pl-4 flex-fill text-right">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>
        <div
            class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
            @click="openUrl('https://www.kicker.de/em/startseite')"
        >
            <div style="width: 45px">
                <font-awesome-icon
                    class="h2 mb-0 text-primary"
                    :icon="['fas', 'info-circle']"
                />
            </div>
            <div>
                <h1 class="h4 font-weight-bold mb-0">News</h1>
                <span class="text-muted mb-0"
                    >Alle News rund um die EM 2024</span
                >
            </div>
            <div class="pl-4 flex-fill text-right">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>
        <div
            class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
            @click="openUrl('https://www.kicker.de/europameisterschaft/teams')"
        >
            <div style="width: 45px">
                <font-awesome-icon
                    class="h2 mb-0 text-primary"
                    :icon="['fas', 'users']"
                />
            </div>
            <div>
                <h1 class="h4 font-weight-bold mb-0">Mannschaften</h1>
                <span class="text-muted mb-0">Alle Teams der EM</span>
            </div>
            <div class="pl-4 flex-fill text-right">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>
    </div>
</template>
<script>
import Wm from '../../../views/Wm'
export default {
    ...Wm,
}
</script>
