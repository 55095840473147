<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <b-overlay :show="false" rounded="sm">
                <div
                    class="
                        shadow-sm
                        bg-white
                        p-3
                        my-2
                        d-flex
                        align-items-center
                    "
                    @click="$router.push('recommendations')"
                >
                    <div style="width: 45px">
                        <font-awesome-icon
                            class="h2 mb-0 text-primary"
                            :icon="['fas', 'magic']"
                        />
                    </div>
                    <div>
                        <h1 class="h4 font-weight-bold mb-0">TV Filme</h1>
                        <span class="text-muted mb-0"
                            >TOP Filme der nächsten 7 Tage</span
                        >
                    </div>
                    <div class="pl-4 flex-fill text-right">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </b-overlay>
            <b-form-select
                v-model="dateQuery"
                :options="dateOptions"
                class="mb-2"
            />
            <div class="header bg-light" id="stations" style="z-index: 1000">
                <div class="stations d-flex py-1">
                    <div
                        class="
                            shadow-sm
                            p-1
                            mx-1
                            bg-white
                            d-flex
                            flex-column
                            align-items-center
                            text-center
                            justify-content-center
                        "
                        v-for="(item, index) in pDayFromNow"
                        :key="'station-' + index"
                        :class="{
                            'border border-primary border-2':
                                item.slug == stationQuery ||
                                (stationQuery === null && index == 0),
                        }"
                        @click="navigateStation(item)"
                    >
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                item.slug +
                                '.png'
                            "
                            style="max-width: 60px"
                        />
                        <div
                            class="mt-2"
                            style="
                                width: 80px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                font-weight: bold;
                            "
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div>
                    <b-alert
                        :show="hProgram === null"
                        dismissible
                        variant="primary"
                        @dismissed="handleBroadcastClick()"
                        style="position: relative; z-index: 1"
                        class="mt-2 mb-0"
                    >
                        Tippe auf
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        um den Livestream zu starten oder auf
                        <font-awesome-icon :icon="['far', 'heart']" />
                        um
                        <strong>10 Minuten vor Beginn</strong> der Sendung
                        benachrichtigt zu werden.
                    </b-alert>
                </div>
                <joyn-item
                    :item="randomJoynItem"
                    source="program"
                    v-if="
                        eligableForJoyn &&
                        everyX &&
                        Object.keys(randomJoynItem).length > 0
                    "
                >
                    <template #header>
                        <span class="badge badge-primary text-white ml-1">
                            <font-awesome-icon :icon="['fas', 'medal']" />
                            FILMTIPP
                        </span>
                    </template>
                </joyn-item>
                <div
                    v-for="(item, index) in activeStation.sections"
                    :key="'section-' + index"
                >
                    <div
                        v-for="(item2, index2) in item.data"
                        :key="'program-' + index2"
                        @click="handleBroadcastClick()"
                    >
                        <broadcast
                            :layout="BROADCAST_LAYOUT_PROGRAM"
                            :item="item2"
                            :c="item2.c"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Program from '../../../views/Program'
export default {
    ...Program,
}
</script>
