<template>
    <div>
        <b-alert
            :show="hMediaLibrary === null"
            dismissible
            variant="primary"
            @dismissed="hideHint()"
        >
            Alle Videos in der Mediathek können zu
            <strong>jeder Zeit</strong> abgespielt werden. Es kommen jeden Tag
            neue Videos hinzu.
        </b-alert>
        <div @click="hideHint">
            <h1 class="h5 font-weight-bold mt-2 mb-2" @click="to('joyn')">
                Filme kostenlos streamen
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="$store.state.medialibrary.movies.loading" />

            <div v-else class="slider">
                <div
                    class="positiion-relative"
                    v-for="item of joynItems"
                    :key="item.slug"
                >
                    <div
                        class="h-100 bg-white ml-2"
                        style="width: 180px"
                        @click="handleJoynItemClick(item)"
                    >
                        <image-item
                            :index="0"
                            :source="item.image_url"
                            class="w-100"
                            height="265px"
                            :test="item.title"
                        />
                        <div class="p-1">
                            <div class="small d-flex align-items-center">
                                <div style="white-space: nowrap">
                                    <font-awesome-icon
                                        :icon="['fas', 'star']"
                                        v-for="n in Math.ceil(
                                            item.vote_average / 2
                                        )"
                                        :key="'aacc' + n"
                                    />
                                    <font-awesome-icon
                                        :icon="['far', 'star']"
                                        v-for="n in 5 -
                                        Math.ceil(item.vote_average / 2)"
                                        :key="'aaacc' + n"
                                    />
                                </div>
                                <span
                                    class="ml-1 text-muted"
                                    style="
                                        overflow: hidden;
                                        white-space: nowrap;
                                    "
                                    >{{ item.vote_count }} Stimmen</span
                                >
                            </div>
                            <strong>{{
                                item.title.length > 40
                                    ? item.title.substring(0, 40) + '...'
                                    : item.title
                            }}</strong>
                        </div>
                    </div>
                </div>
                <div
                    style="height: 330px"
                    class="
                        shadow-sm
                        bg-white
                        m-1
                        p-3
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    @click="to('joyn')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>
            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="to('medialibrary-recommendations')"
            >
                Dokus
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="$store.state.medialibrary.recommendations.loading" />
            <div v-else class="slider">
                <media-library-item
                    v-for="(item, index) in recommendations.filter(
                        (item, index) => index < 40
                    )"
                    :key="'b-' + index"
                    :index="index"
                    :item="item"
                    :layout="1"
                />
                <div
                    class="
                        shadow-sm
                        bg-white
                        m-1
                        p-3
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    @click="to('medialibrary-recommendations')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>
            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="to('medialibrary-channels')"
            >
                Kanäle
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="loading" />
            <div v-else class="slider">
                <channel
                    v-for="(item, index) in channels.filter(
                        (item, index) => index < 20
                    )"
                    :item="item"
                    :key="'channel-' + index"
                />
                <div
                    class="
                        shadow-sm
                        bg-white
                        m-1
                        p-3
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    @click="to('medialibrary-channels')"
                >
                    <div
                        style="min-width: 60px"
                        class="h5 font-weight-bold mb-0"
                    >
                        Alle
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </div>
                </div>
            </div>

            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="to('medialibrary-latest')"
            >
                Neuste Videos
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="loading" />
            <div v-else class="slider">
                <media-library-item
                    v-for="(item, index) in latest.filter(
                        (item, index) => index < 40
                    )"
                    :key="'b-' + index"
                    :index="index"
                    :item="item"
                    :layout="1"
                />
                <div
                    class="
                        shadow-sm
                        bg-white
                        m-1
                        p-3
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    @click="to('medialibrary-latest')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>
        </div>
        <joyn-modal
            :item="selectedMovie"
            source="medialibrary_home"
            v-model="selectedMovieModal"
            title="🔥 Kostenlos anschauen"
            :hide-mute-message="true"
        />
    </div>
</template>
<script>
import MediaLibraryIndex from '../../../views/MediaLibraryIndex'
export default {
    ...MediaLibraryIndex,
}
</script>
