<template>
    <div>
        <loading v-if="$store.state.program.loading" />
        <div v-else>
            <h5 class="font-weight-bold my-0 mb-1">Jetzt LIVE im TV</h5>
            <div class="my-2">
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'pNow',
                        'btn-outline-primary': selected != 'pNow',
                    }"
                    @click="selected = 'pNow'"
                >
                    Jetzt
                </button>
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'pAfter',
                        'btn-outline-primary': selected != 'pAfter',
                    }"
                    @click="selected = 'pAfter'"
                >
                    Danach
                </button>
            </div>
            <b-alert
                :show="hTodayLive === null"
                dismissible
                variant="primary"
                @dismissed="handleClick()"
            >
                Tippe auf
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                um den Livestream zu starten.
            </b-alert>

            <div v-if="selected == 'pNow'">
                <div
                    v-for="(item, index) in pNow"
                    :key="index"
                    @click="handleClick()"
                >
                    <joyn-item
                        :item="randomJoynItem"
                        source="now_live"
                        v-if="
                            index == 0 &&
                            eligableForJoyn &&
                            everyX &&
                            Object.keys(randomJoynItem).length > 0
                        "
                    >
                        <template #header>
                            <span class="badge badge-primary text-white ml-1">
                                <font-awesome-icon :icon="['fas', 'medal']" />
                                FILMTIPP
                            </span>
                        </template>
                    </joyn-item>
                    <broadcast
                        :layout="BROADCAST_LAYOUT_LIVE"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
            <div v-if="selected == 'pAfter'">
                <div
                    v-for="(item, index) in pAfter"
                    :key="index"
                    @click="handleClick()"
                >
                    <broadcast
                        :layout="BROADCAST_LAYOUT_LIVE"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Live from '../../../views/Live'
export default {
    ...Live,
}
</script>
