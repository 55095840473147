<template>
    <div>
        <b-modal
            v-model="show"
            centered
            modal-class="modal-fullscreen"
            body-class="modal-body-custom"
            :title="title"
            hide-footer
            ok-only
        >
            <template #modal-header>
                <div class="w-100 d-flex align-items-center">
                    <div class="p-2 h5 mb-0" @click="show = false">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>

            <div
                v-if="Object.keys(item).length"
                class="d-flex justify-content-center"
            >
                <div>
                    <div class="img-container" @click="start">
                        <img
                            :src="item.cover_url"
                            class="w-100"
                            v-if="item.cover_url"
                        />
                        <div
                            v-else
                            class="w-100"
                            :style="{
                                height: '200px',
                                background: 'url(' + item.image_url + ')',
                            }"
                        ></div>
                        <div class="overlay d-flex align-items-center">
                            <img
                                :src="item.image_url"
                                height="80%"
                                class="rounded shadow ml-2"
                            />
                            <div
                                style="
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                "
                                class="ml-2 small"
                            >
                                Bildrechte tmdb.org
                            </div>
                        </div>
                    </div>
                    <div class="pt-3 pb-1 px-2">
                        <h5
                            style="
                                color: #000;
                                font-weight: bold;
                                text-align: center;
                            "
                        >
                            {{ item.title }}
                        </h5>
                    </div>
                    <div
                        class="
                            row
                            justify-content-around
                            align-items-center
                            py-2
                        "
                    >
                        <div class="col-6 text-center">
                            <div style="white-space: nowrap">
                                <font-awesome-icon
                                    :icon="['fas', 'star']"
                                    v-for="n in Math.ceil(
                                        item.vote_average / 2
                                    )"
                                    :key="'aacc' + n"
                                />
                                <font-awesome-icon
                                    :icon="['far', 'star']"
                                    v-for="n in 5 -
                                    Math.ceil(item.vote_average / 2)"
                                    :key="'aaacc' + n"
                                />
                            </div>

                            <div
                                class="text-muted"
                                style="overflow: hidden; white-space: nowrap"
                            >
                                {{ item.vote_count }} Stimmen
                            </div>
                        </div>
                        <div
                            class="col-6 border-left text-center"
                            style="color: #000; font-weight: bold"
                        >
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="start"
                            >
                                <span class="mr-1">▶</span> Film starten
                            </button>
                        </div>
                    </div>
                    <div class="bg-light text-center px-2 py-3">
                        <strong>{{ item.tmdb_runtime }} Minuten</strong><br />
                        {{ item.tmdb_genres }}
                    </div>
                    <div class="text-muted text-center pt-3 pb-1 px-3">
                        {{ item.description }}
                    </div>
                    <hr class="mb-0" />
                    <button
                        v-if="!hideMuteMessage"
                        class="btn btn-block py-3 text-dark btn-link"
                        @click="mute()"
                    >
                        🔇 Filmtipp nicht mehr erhalten
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
    name: 'JoynModal',
    props: ['item', 'source', 'value', 'title', 'hideMuteMessage'],
    data: () => ({
        dayjs,
        show: false,
    }),
    methods: {
        hideModal() {
            this.show = false
            this.$router.push({
                query: { ...this.$route.query, slug: undefined },
            })
        },
        mute() {
            //this.hideModal()
            this.$store.state.user.settings.movie_notifications = false
            this.updateSettings()
            //this.$router.push('/settings?movie_notifications=false')
        },
        start() {
            this.hideModal()
            this.startJoyn(this.item, this.source)
        },
        updateSettings() {
            this.$store
                .dispatch('user/updateSettings')
                .catch((e) => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.nativeBridge(
                        'showToast',
                        'Du erhälst ab sofort keine Filmtipps mehr.'
                    )
                })
        },
    },
    watch: {
        value: function (n, o) {
            this.show = n
        },
        show: function (n, o) {
            this.$emit('input', n)
        },
    },
}
</script>
<style lang="scss" scoped>
.img-container {
    position: relative;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        rgba(0, 0, 0, 0)
    );
}
</style>
