var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.series.recommendations.loading)?_c('loading'):_c('div',[_c('h5',{staticClass:"font-weight-bold my-0 mb-1"},[_vm._v("TOP Filme im TV")]),_c('div',{staticClass:"days d-flex py-2"},_vm._l((_vm.days),function(item,index){return _c('div',{key:'date-' + index,staticClass:"shadow-sm py-1 px-3 mx-1 bg-white text-center",class:{
                    'text-primary': item.isToday,
                    'border border-2 border-primary':
                        _vm.joynSelected === false &&
                        (item.format('YYYY-MM-DD') == _vm.day ||
                            (_vm.day === null && index == 0)),
                },on:{"click":function($event){return _vm.navigateDate(item)}}},[_c('small',{staticClass:"text-muted"},[_c('span',{class:{
                            'text-danger':
                                item.format('dd') == 'Sa' ||
                                item.format('dd') == 'So',
                        }},[_vm._v(_vm._s(item.format('dd')))])]),_c('br'),_c('h4',{staticClass:"m-0 font-weight-bolder"},[_vm._v(" "+_vm._s(item.format('D'))+" ")]),(false && item.isSame(new Date(), 'day'))?_c('small',{staticStyle:{"margin-top":"-2px","display":"block"}},[_vm._v("heute")]):_vm._e()])}),0),_vm._l((_vm.future),function(item,index){return _c('div',{key:'f-' + index,on:{"click":function($event){return _vm.hideHint()}}},_vm._l((item.items),function(item2,index2){return _c('broadcast',{key:'f2' + index2,attrs:{"layout":_vm.BROADCAST_LAYOUT_RECOMMENDATION_FULL,"item":item2,"c":item2.c,"imdb":""}})}),1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }