var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('loading'):_c('div',[_c('b-overlay',{attrs:{"show":false,"rounded":"sm"}},[_c('div',{staticClass:"\n                    shadow-sm\n                    bg-white\n                    p-3\n                    my-2\n                    d-flex\n                    align-items-center\n                ",on:{"click":function($event){return _vm.$router.push('recommendations')}}},[_c('div',{staticStyle:{"width":"45px"}},[_c('font-awesome-icon',{staticClass:"h2 mb-0 text-primary",attrs:{"icon":['fas', 'magic']}})],1),_c('div',[_c('h1',{staticClass:"h4 font-weight-bold mb-0"},[_vm._v("TV Filme")]),_c('span',{staticClass:"text-muted mb-0"},[_vm._v("TOP Filme der nächsten 7 Tage")])]),_c('div',{staticClass:"pl-4 flex-fill text-right"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)])]),_c('b-form-select',{staticClass:"mb-2",attrs:{"options":_vm.dateOptions},model:{value:(_vm.dateQuery),callback:function ($$v) {_vm.dateQuery=$$v},expression:"dateQuery"}}),_c('div',{staticClass:"header bg-light",staticStyle:{"z-index":"1000"},attrs:{"id":"stations"}},[_c('div',{staticClass:"stations d-flex py-1"},_vm._l((_vm.pDayFromNow),function(item,index){return _c('div',{key:'station-' + index,staticClass:"\n                        shadow-sm\n                        p-1\n                        mx-1\n                        bg-white\n                        d-flex\n                        flex-column\n                        align-items-center\n                        text-center\n                        justify-content-center\n                    ",class:{
                        'border border-primary border-2':
                            item.slug == _vm.stationQuery ||
                            (_vm.stationQuery === null && index == 0),
                    },on:{"click":function($event){return _vm.navigateStation(item)}}},[_c('img',{staticStyle:{"max-width":"60px"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                            item.slug +
                            '.png'}}),_c('div',{staticClass:"mt-2",staticStyle:{"width":"80px","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)]),_c('div',{staticClass:"content"},[_c('div',[_c('b-alert',{staticClass:"mt-2 mb-0",staticStyle:{"position":"relative","z-index":"1"},attrs:{"show":_vm.hProgram === null,"dismissible":"","variant":"primary"},on:{"dismissed":function($event){return _vm.handleBroadcastClick()}}},[_vm._v(" Tippe auf "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}}),_vm._v(" um den Livestream zu starten oder auf "),_c('font-awesome-icon',{attrs:{"icon":['far', 'heart']}}),_vm._v(" um "),_c('strong',[_vm._v("10 Minuten vor Beginn")]),_vm._v(" der Sendung benachrichtigt zu werden. ")],1)],1),(
                    _vm.eligableForJoyn &&
                    _vm.everyX &&
                    Object.keys(_vm.randomJoynItem).length > 0
                )?_c('joyn-item',{attrs:{"item":_vm.randomJoynItem,"source":"program"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"badge badge-primary text-white ml-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'medal']}}),_vm._v(" FILMTIPP ")],1)]},proxy:true}],null,false,1735225548)}):_vm._e(),_vm._l((_vm.activeStation.sections),function(item,index){return _c('div',{key:'section-' + index},_vm._l((item.data),function(item2,index2){return _c('div',{key:'program-' + index2,on:{"click":function($event){return _vm.handleBroadcastClick()}}},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_PROGRAM,"item":item2,"c":item2.c}})],1)}),0)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }