<template>
    <div>
        <div
            class="d-flex align-items-center justify-content-between py-1 mt-4"
            @click="openPremiumModal()"
        >
            <div>
                <h5 class="font-weight-bold mb-0">
                    <font-awesome-icon :icon="['fas', 'crown']" class="mr-1" />
                    Werbung entfernen
                </h5>
                <small class="text-muted">Hol dir jetzt LIVE TV Pro</small>
            </div>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />

        <div class="d-flex align-items-center justify-content-between">
            <div @click="wifi_only = !wifi_only">
                <h5 class="font-weight-bold mb-0">Nur über WLAN streamen</h5>
                <small class="text-muted">Mobiles Datenvolumen sparen</small>
            </div>
            <div class="pl-4">
                <b-form-checkbox
                    v-model="wifi_only"
                    switch
                    size="lg"
                ></b-form-checkbox>
            </div>
        </div>
        <div>
            <hr class="my-4" />
            <div class="d-flex align-items-center justify-content-between">
                <div @click="movieNotifications = !movieNotifications">
                    <h5 class="font-weight-bold mb-0">Filmtipps</h5>
                    <small class="text-muted"
                        >Filmtipps per Benachrichtung erhalten</small
                    >
                </div>
                <div class="pl-4">
                    <b-form-checkbox
                        v-model="movieNotifications"
                        switch
                        size="lg"
                    ></b-form-checkbox>
                </div>
            </div>
        </div>
        <div v-if="fullscreenMode !== undefined">
            <hr class="my-4" />
            <div class="d-flex align-items-center justify-content-between">
                <div @click="fullscreenMode = !fullscreenMode">
                    <h5 class="font-weight-bold mb-0">
                        App immer im Vollbildmodus
                    </h5>
                    <small class="text-muted"
                        >Vollbild auch auf dem Home-Screen aktivieren</small
                    >
                </div>
                <div class="pl-4">
                    <b-form-checkbox
                        v-model="fullscreenMode"
                        switch
                        size="lg"
                    ></b-form-checkbox>
                </div>
            </div>
        </div>
        <!--
        <hr class="my-3" />
        <div class="d-flex align-items-center justify-content-between">
            <div @click="notification.muted = !notification.muted">
                <h5 class="font-weight-bold mb-0">
                    Benachrichtigungston
                </h5>
                <small class="text-muted"
                    >Schalte deine Benachrichtigungen stumm</small
                >
            </div>
            <div class="pl-4">
                <b-form-checkbox
                    v-model="notification.muted"
                    switch
                    size="lg"
                ></b-form-checkbox>
            </div>
        </div>
        -->
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.report.show = true"
        >
            <h5 class="font-weight-bold mb-0">Problem melden</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.suggestion.show = true"
        >
            <h5 class="font-weight-bold mb-0">Sender vorschlagen</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.about.show = true"
        >
            <h5 class="font-weight-bold mb-0">Über Uns</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <div v-if="version >= 58">
            <hr class="my-4" />
            <div
                class="d-flex align-items-center justify-content-between py-1"
                @click="openDsgvoConfig()"
            >
                <div>
                    <h5 class="font-weight-bold mb-0">DSGVO</h5>
                    <small class="text-muted">Einstellungen anpassen</small>
                </div>
                <font-awesome-icon :icon="['fas', 'angle-right']" />
            </div>
        </div>
        <hr class="my-4" />
        <button
            class="btn btn-lg btn-block btn-outline-danger"
            @click="nativeBridge('closeApp')"
        >
            App schließen
        </button>
        <b-modal
            v-model="modals.report.show"
            centered
            title="Ein Problem melden"
            cancel-title="Abbrechen"
            ok-title="Problem melden"
            @shown="focusInput"
            @ok="onSubmitReport"
        >
            <form>
                <div class="alert alert-success" v-if="modals.report.success">
                    <strong>Vielen Dank für dein Feedback!</strong><br />Wir
                    kümmern uns schnellstmöglich um das Problem. Falls du eine
                    E-Mail angegeben hast melden wir uns bei dir!
                </div>
                <div class="alert alert-danger" v-if="modals.report.error">
                    <strong>Hoppla!</strong><br />Beim Absenden des Formulars
                    ist ein Fehler aufgetreten. Bitte probiere es in Kürze
                    erneut.
                </div>
                <div class="form-group">
                    <label for="email" class="mb-0 font-weight-bold"
                        >E-Mail
                        <small
                            >(nicht nötig, aber nur so können wir dir
                            antworten)</small
                        ></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="$v.modals.report.form.email.$model"
                        placeholder="Eingeben..."
                        :class="{ 'is-invalid': !validateState('email') }"
                    />
                </div>
                <div class="form-group">
                    <label for="message" class="mb-0 font-weight-bold"
                        >Nachricht <small>(nicht nötig)</small></label
                    >
                    <textarea
                        class="form-control"
                        name="message"
                        ref="focusThis"
                        id="message"
                        placeholder="Eingeben..."
                        rows="4"
                        v-model="modals.report.form.message"
                    ></textarea>
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="modals.suggestion.show"
            centered
            title="Verbesserungen vorschlagen"
            cancel-title="Abbrechen"
            ok-title="Vorschlag absenden"
            @shown="focusInput2"
            @ok="onSubmitSuggestion"
        >
            <form>
                <div
                    class="alert alert-success"
                    v-if="modals.suggestion.success"
                >
                    <strong>Vielen Dank für dein Feedback!</strong><br />Wir
                    kümmern uns schnellstmöglich um das Problem. Falls du eine
                    E-Mail angegeben hast melden wir uns bei dir!
                </div>
                <div class="alert alert-danger" v-if="modals.suggestion.error">
                    <strong>Hoppla!</strong><br />Beim Absenden des Formulars
                    ist ein Fehler aufgetreten. Bitte probiere es in Kürze
                    erneut.
                </div>
                <div class="form-group">
                    <label for="email" class="mb-0 font-weight-bold"
                        >E-Mail
                        <small
                            >(nicht nötig, aber nur so können wir dir
                            antworten)</small
                        ></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="$v.modals.suggestion.form.email.$model"
                        placeholder="Eingeben..."
                        :class="{ 'is-invalid': !validateState2('email') }"
                    />
                </div>
                <div class="form-group">
                    <label for="message" class="mb-0 font-weight-bold"
                        >Nachricht <small>(nicht nötig)</small></label
                    >
                    <textarea
                        class="form-control"
                        name="message"
                        ref="focusThis2"
                        id="message"
                        placeholder="Eingeben..."
                        rows="4"
                        v-model="modals.suggestion.form.message"
                    ></textarea>
                </div>
            </form>
        </b-modal>
        <b-modal
            v-model="modals.about.show"
            centered
            title="Über diese App"
            hide-footer
        >
            <strong>Name</strong><br />
            Deutsches Fernsehen - LIVE TV kostenlos gucken<br /><br />
            <strong>Email</strong><br />
            support@vanced-app.com
            <br /><br />
            <strong>Webseite</strong><br />
            <a href="http://www.vanced-app.com">http://www.vanced-app.com</a>
            <br /><br />
            <strong>Google Play</strong><br />
            <a
                href="https://play.google.com/store/apps/details?id=com.tv.live.deutsches.fernsehen"
                >https://play.google.com/store/apps/details?id=com.tv.live.deutsches.fernsehen</a
            >
            <br /><br />
            <strong>Privacy Policy</strong><br />
            <a href="http://www.vanced-app.com/privacy-policy.php"
                >http://www.vanced-app.com/privacy-policy.php</a
            >
            <br /><br />
            <strong>Version</strong><br />
            {{ version }}
        </b-modal>
    </div>
</template>
<script>
import Settings from '../../../views/Settings'
export default {
    ...Settings,
}
</script>
