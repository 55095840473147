var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"show":_vm.hMediaLibrary === null,"dismissible":"","variant":"primary"},on:{"dismissed":function($event){return _vm.hideHint()}}},[_vm._v(" Alle Videos in der Mediathek können zu "),_c('strong',[_vm._v("jeder Zeit")]),_vm._v(" abgespielt werden. Es kommen jeden Tag neue Videos hinzu. ")]),_c('div',{on:{"click":_vm.hideHint}},[_c('h1',{staticClass:"h5 font-weight-bold mt-2 mb-2",on:{"click":function($event){return _vm.to('joyn')}}},[_vm._v(" Filme kostenlos streamen "),_c('font-awesome-icon',{staticClass:"ml-2 small",attrs:{"icon":['fas', 'chevron-right']}})],1),(_vm.$store.state.medialibrary.movies.loading)?_c('loading'):_c('div',{staticClass:"slider"},[_vm._l((_vm.joynItems),function(item){return _c('div',{key:item.slug,staticClass:"positiion-relative"},[_c('div',{staticClass:"h-100 bg-white ml-2",staticStyle:{"width":"180px"},on:{"click":function($event){return _vm.handleJoynItemClick(item)}}},[_c('image-item',{staticClass:"w-100",attrs:{"index":0,"source":item.image_url,"height":"265px","test":item.title}}),_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"small d-flex align-items-center"},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._l((Math.ceil(
                                        item.vote_average / 2
                                    )),function(n){return _c('font-awesome-icon',{key:'aacc' + n,attrs:{"icon":['fas', 'star']}})}),_vm._l((5 -
                                    Math.ceil(item.vote_average / 2)),function(n){return _c('font-awesome-icon',{key:'aaacc' + n,attrs:{"icon":['far', 'star']}})})],2),_c('span',{staticClass:"ml-1 text-muted",staticStyle:{"overflow":"hidden","white-space":"nowrap"}},[_vm._v(_vm._s(item.vote_count)+" Stimmen")])]),_c('strong',[_vm._v(_vm._s(item.title.length > 40 ? item.title.substring(0, 40) + '...' : item.title))])])],1)])}),_c('div',{staticClass:"\n                    shadow-sm\n                    bg-white\n                    m-1\n                    p-3\n                    d-flex\n                    flex-column\n                    justify-content-center\n                    align-items-center\n                ",staticStyle:{"height":"330px"},on:{"click":function($event){return _vm.to('joyn')}}},[_c('span',{staticClass:"h5 font-weight-bold mb-0",staticStyle:{"min-width":"100px"}},[_vm._v(" Alle "),_c('br'),_vm._v("anzeigen "),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":['fas', 'chevron-right']}})],1)])],2),_c('h1',{staticClass:"h5 font-weight-bold mt-4 mb-2",on:{"click":function($event){return _vm.to('medialibrary-recommendations')}}},[_vm._v(" Dokus "),_c('font-awesome-icon',{staticClass:"ml-2 small",attrs:{"icon":['fas', 'chevron-right']}})],1),(_vm.$store.state.medialibrary.recommendations.loading)?_c('loading'):_c('div',{staticClass:"slider"},[_vm._l((_vm.recommendations.filter(
                    function (item, index) { return index < 40; }
                )),function(item,index){return _c('media-library-item',{key:'b-' + index,attrs:{"index":index,"item":item,"layout":1}})}),_c('div',{staticClass:"\n                    shadow-sm\n                    bg-white\n                    m-1\n                    p-3\n                    d-flex\n                    flex-column\n                    justify-content-center\n                    align-items-center\n                ",on:{"click":function($event){return _vm.to('medialibrary-recommendations')}}},[_c('span',{staticClass:"h5 font-weight-bold mb-0",staticStyle:{"min-width":"100px"}},[_vm._v(" Alle "),_c('br'),_vm._v("anzeigen "),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":['fas', 'chevron-right']}})],1)])],2),_c('h1',{staticClass:"h5 font-weight-bold mt-4 mb-2",on:{"click":function($event){return _vm.to('medialibrary-channels')}}},[_vm._v(" Kanäle "),_c('font-awesome-icon',{staticClass:"ml-2 small",attrs:{"icon":['fas', 'chevron-right']}})],1),(_vm.loading)?_c('loading'):_c('div',{staticClass:"slider"},[_vm._l((_vm.channels.filter(
                    function (item, index) { return index < 20; }
                )),function(item,index){return _c('channel',{key:'channel-' + index,attrs:{"item":item}})}),_c('div',{staticClass:"\n                    shadow-sm\n                    bg-white\n                    m-1\n                    p-3\n                    d-flex\n                    flex-column\n                    justify-content-center\n                    align-items-center\n                ",on:{"click":function($event){return _vm.to('medialibrary-channels')}}},[_c('div',{staticClass:"h5 font-weight-bold mb-0",staticStyle:{"min-width":"60px"}},[_vm._v(" Alle "),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":['fas', 'chevron-right']}})],1)])],2),_c('h1',{staticClass:"h5 font-weight-bold mt-4 mb-2",on:{"click":function($event){return _vm.to('medialibrary-latest')}}},[_vm._v(" Neuste Videos "),_c('font-awesome-icon',{staticClass:"ml-2 small",attrs:{"icon":['fas', 'chevron-right']}})],1),(_vm.loading)?_c('loading'):_c('div',{staticClass:"slider"},[_vm._l((_vm.latest.filter(
                    function (item, index) { return index < 40; }
                )),function(item,index){return _c('media-library-item',{key:'b-' + index,attrs:{"index":index,"item":item,"layout":1}})}),_c('div',{staticClass:"\n                    shadow-sm\n                    bg-white\n                    m-1\n                    p-3\n                    d-flex\n                    flex-column\n                    justify-content-center\n                    align-items-center\n                ",on:{"click":function($event){return _vm.to('medialibrary-latest')}}},[_c('span',{staticClass:"h5 font-weight-bold mb-0",staticStyle:{"min-width":"100px"}},[_vm._v(" Alle "),_c('br'),_vm._v("anzeigen "),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":['fas', 'chevron-right']}})],1)])],2)],1),_c('joyn-modal',{attrs:{"item":_vm.selectedMovie,"source":"medialibrary_home","title":"🔥 Kostenlos anschauen","hide-mute-message":true},model:{value:(_vm.selectedMovieModal),callback:function ($$v) {_vm.selectedMovieModal=$$v},expression:"selectedMovieModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }