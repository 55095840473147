<template>
    <div>
        <joyn-modal
            :item="modal.movie"
            source="notification"
            v-model="modal.show"
            title="💡 Filmtipp für Dich"
            :hide-mute-message="false"
        />
        <joyn-modal
            :item="selectedMovie"
            source="movies"
            v-model="selectedMovieModal"
            title="🔥 Kostenlos anschauen"
            :hide-mute-message="true"
        />
        <b-alert
            :show="hJoynEveryDay === null"
            dismissible
            variant="primary"
            @dismissed="hideHint()"
        >
            💡 Tipp: Unsere Redaktion aktualisiert jeden Tag die Liste der
            Filme. Es lohnt sich also jeden Tag hier reinzuschauen.
        </b-alert>
        <h5 class="h4 font-weight-bold my-2 text-center">
            Ganze Filme kostenlos streamen
        </h5>

        <div class="py-3 mb-1 mt-n2" style="overflow-x: auto">
            <div class="d-flex">
                <div
                    style="white-space: nowrap"
                    class="
                        border
                        bg-white
                        pointer
                        px-3
                        py-2
                        mr-1
                        font-weight-bold
                        rounded
                    "
                    :class="{
                        'border-dark': category.slug === selectedCategorySlug,
                    }"
                    v-for="category of categories"
                    :key="category.slug"
                    @click="selectCategory(category.slug)"
                >
                    {{ category.label }}
                </div>
            </div>
        </div>
        <loading v-if="loading" />
        <div v-else>
            <div v-if="items.length === 0">
                Schau hier gern später noch einmal vorbei. Unsere Redaktion
                sucht für dich in diesem Moment weitere tolle Filme.
            </div>
            <div v-else class="row row-smaller">
                <div class="col-12">
                    <div class="mt-1 mb-3 d-flex justify-content-start">
                        <b-form-checkbox
                            v-model="withoutRegistration"
                            size="lg"
                            switch
                            >Auch Filme
                            <strong>mit Anmeldung</strong>
                            anzeigen</b-form-checkbox
                        >
                    </div>
                </div>
                <div
                    class="col-6 positiion-relative mb-3"
                    v-for="(item, index) of items"
                    :key="item.slug"
                >
                    <div
                        class="w-100 h-100 bg-white rounded"
                        @click="handleJoynItemClick(item)"
                    >
                        <image-item
                            :index="index"
                            :source="item.image_url"
                            class="w-100 mb-2"
                        />
                        <div class="p-1">
                            <div class="small d-flex align-items-center">
                                <div style="white-space: nowrap">
                                    <font-awesome-icon
                                        :icon="['fas', 'star']"
                                        v-for="n in Math.ceil(
                                            item.vote_average / 2
                                        )"
                                        :key="'aacc' + n"
                                    />
                                    <font-awesome-icon
                                        :icon="['far', 'star']"
                                        v-for="n in 5 -
                                        Math.ceil(item.vote_average / 2)"
                                        :key="'aaacc' + n"
                                    />
                                </div>
                                <span
                                    class="ml-1 text-muted"
                                    style="
                                        overflow: hidden;
                                        white-space: nowrap;
                                    "
                                    >{{ item.vote_count }} Stimmen</span
                                >
                            </div>
                            <strong>{{
                                item.title.length > 40
                                    ? item.title.substring(0, 40) + '...'
                                    : item.title
                            }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Joyn from '../../../views/Joyn'
export default {
    ...Joyn,
}
</script>
