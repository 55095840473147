<template>
    <div>
        <loading v-if="loading" />
        <div v-else>
            <div v-if="search.loading">
                <b-spinner class="my-4 mx-2" label="Laden..."></b-spinner>
            </div>
            <div v-if="!search.loading">
                <div class="text-left" v-if="search.keyword.length >= 1">
                    <span v-if="searchResults.length == 0"
                        >Keine Ergebnisse</span
                    >
                    <span v-if="searchResults.length == 1">1 Ergebnis</span>
                    <span
                        v-if="
                            searchResults.length >= 2 &&
                            searchResults.length < 49
                        "
                        >{{ searchResults.length }} Ergebnisse</span
                    >
                    <span v-if="searchResults.length == 50"
                        >über {{ searchResults.length }} Ergebnisse</span
                    >
                </div>

                <div v-for="(item, index) in searchResults" :key="'s-' + index">
                    <broadcast :layout="BROADCAST_LAYOUT_SEARCH" :item="item" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SearchWm from '../../../views/SearchWm'
export default {
    ...SearchWm,
}
</script>
