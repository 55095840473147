<template>
    <div>
        <div
            class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
            @click="cc('bookmarks')"
        >
            <div style="width: 45px">
                <font-awesome-icon
                    class="h2 mb-0 text-primary"
                    :icon="['fas', 'heart']"
                />
            </div>
            <div>
                <h1 class="h4 font-weight-bold mb-0">Mein Programm</h1>
                <span class="text-muted mb-0"
                    >Wann laufen meine Sendungen?</span
                >
                <!--
                <span
                    class="text-muted mb-0"
                    v-if="sumUpcomingBroadcasts > 0"
                    >{{ sumUpcomingBroadcasts }} vorgemerkte Sendungen</span
                >
                <span
                    class="text-muted mb-0"
                    v-if="sumUpcomingBroadcasts == 0"
                    >Keine Ausstrahlungen für Heute</span
                >
                -->
            </div>
            <div class="pl-4 flex-fill text-right">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
        </div>
        <div
            class="d-flex align-items-center mt-3 mb-2"
            @click="openFavoritesModal(true)"
        >
            <h5 class="page-title mb-0 mt-0">Meine Sender</h5>
            <span v-if="favorites.length" class="text-primary">
                <font-awesome-icon :icon="['fas', 'pen']" class="ml-2" />
            </span>
        </div>

        <loading v-if="$store.state.user.stations.loading" />
        <div v-else>
            <b-alert
                v-if="!favorites.length"
                show
                dismissible
                variant="primary"
            >
                Klicke auf
                <strong>
                    <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="text-primary"
                /></strong>
                um deine Lieblingssender auszuwählen. Deine Lieblingssender
                werden im Programm als erstes angezeigt.
            </b-alert>
            <div class="d-flex flex-wrap align-self-stretch">
                <div
                    v-for="(item, index) in favorites"
                    :key="'a-' + index"
                    class="
                        rounded
                        shadow-sm
                        bg-white
                        m-1
                        p-1
                        text-center
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    @click="openStationModal(item)"
                >
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                            item.slug +
                            '.png'
                        "
                        :alt="item.name"
                        style="max-width: 60px"
                    />
                    <div
                        style="
                            width: 80px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        "
                    >
                        <strong>{{ item.name }}</strong>
                    </div>
                </div>
                <div
                    class="
                        shadow-sm
                        bg-white
                        m-1
                        p-1
                        text-center
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                    style="min-height: 80px"
                    @click="openFavoritesModal()"
                >
                    <div
                        style="
                            width: 80px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        "
                    >
                        <span class="h4 mb-0 font-weight-bold">
                            <font-awesome-icon
                                :icon="['fas', 'plus']"
                                class="text-primary"
                            />
                            <br />
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="d-flex align-items-center mt-3 mb-2"
            @click="openAddSeriesModal(true)"
        >
            <h5 class="page-title mb-0 mt-0">Meine Sendungen</h5>
            <span v-if="userSeries.length" class="text-primary">
                <font-awesome-icon :icon="['fas', 'pen']" class="ml-2" />
            </span>
        </div>
        <loading v-if="$store.state.series.all.loading" />
        <div v-else>
            <b-alert
                v-if="!userSeries.length"
                show
                dismissible
                variant="primary"
            >
                Klicke auf
                <strong>
                    <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="text-primary"
                /></strong>
                um deine Lieblingssendungen auszuwählen. Du wirst dann
                <strong>10 Minuten</strong> vor Beginn benachrichtigt.
            </b-alert>
            <div
                v-for="(item, index) in userSeries"
                :key="'aa-' + index"
                class="
                    d-flex
                    align-items-center
                    justify-content-between
                    flex-nowrap
                    m-0
                    my-2
                    p-2
                    shadow-sm
                    bg-white
                "
                @click="openSeriesModal(item)"
            >
                <div class="d-flex align-items-center">
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                            item.station_slug +
                            '.png'
                        "
                        style="max-width: 50px"
                    />
                    <div class="pl-2">
                        <strong class="d-block text-gray-dark">
                            {{ item.series_name }}
                        </strong>
                        <span class="text-muted">{{ item.station_name }}</span>
                    </div>
                </div>
                <div class="pr-2">
                    <font-awesome-icon
                        :icon="['fas', 'heart']"
                        class="text-primary"
                    />
                </div>
            </div>
            <div
                class="
                    shadow-sm
                    bg-white
                    p-1
                    text-center
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                "
                style="width: 80px; height: 80px"
                @click="openAddSeriesModal()"
            >
                <font-awesome-icon
                    :icon="['fas', 'plus']"
                    class="text-primary h4 mb-0 font-weight-bold"
                />
            </div>
        </div>
        <b-modal
            v-model="modals.favorites.show"
            title=""
            scrollable
            centered
            body-bg-variant="light"
            @ok="saveFavorites()"
            @hidden="onFavoritesModalHidden()"
        >
            <template #modal-header>
                <div>
                    <h5 class="mb-0">Meine Sender</h5>
                </div>
            </template>
            <template #modal-footer="{ ok, cancel }">
                <button class="btn btn-secondary" @click="cancel()">
                    Abbrechen
                </button>
                <button class="btn btn-primary" @click="ok()">Speichern</button>
            </template>
            <div v-if="!modals.favorites.hide">
                <div
                    class="text-center w-100 mb-3"
                    @click="modals.favorites.collapsed = false"
                    v-if="
                        modals.favorites.collapsed &&
                        modals.favorites.selected.length
                    "
                >
                    <button
                        type="button"
                        class="btn btn-block btn-outline-primary"
                    >
                        Meine Sender anzeigen ({{
                            modals.favorites.selected.length
                        }})
                    </button>
                </div>
                <div v-if="!modals.favorites.collapsed">
                    <draggable
                        tag="ul"
                        :list="modals.favorites.selected"
                        class="list-group mt-1"
                        handle=".handle"
                    >
                        <li
                            class="
                                list-group-item
                                d-flex
                                align-items-center
                                justify-content-between
                                my-1
                                p-2
                                shadow-sm
                            "
                            v-for="(item, index) in modals.favorites.selected"
                            :key="'b-' + index"
                        >
                            <div class="d-flex align-items-center">
                                <div class="text-left">
                                    <img
                                        :src="
                                            'images/senderlogos/live_ic_tab_' +
                                            item.slug +
                                            '.png'
                                        "
                                        :alt="item.name"
                                        style="max-width: 40px"
                                    />
                                </div>
                                <div class="ml-3">
                                    <strong class="mb-0">{{
                                        item.name
                                    }}</strong>
                                </div>
                            </div>
                            <div>
                                <span class="px-2 py-1 handle mr-4">
                                    <font-awesome-icon
                                        :icon="['fas', 'exchange-alt']"
                                        class="fa-rotate-90"
                                    />
                                </span>
                                <span
                                    class="px-2 py-1"
                                    @click="toggleFavorites(item)"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'trash-alt']"
                                    />
                                </span>
                            </div>
                        </li>
                    </draggable>
                    <div
                        v-if="!modals.favorites.editMode"
                        class="text-center w-100 mb-3 mt-1"
                        @click="modals.favorites.collapsed = true"
                    >
                        <button
                            type="button"
                            class="btn btn-block btn-outline-primary"
                        >
                            Meine Sender ausblenden
                        </button>
                    </div>
                </div>
                <span
                    class="text-muted"
                    v-if="
                        modals.favorites.selected.length == 0 &&
                        !modals.favorites.editMode
                    "
                >
                    Wähle deine Lieblingssender aus
                </span>
                <hr v-if="!modals.favorites.editMode" />
            </div>
            <div v-if="!modals.favorites.editMode">
                <div class="input-group my-2">
                    <input
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Sender suchen..."
                        @click="
                            modals.favorites.collapsed = true
                            modals.favorites.hide = true
                        "
                        @keyup="
                            modals.favorites.search.keyword =
                                $event.target.value
                        "
                        :value="modals.favorites.search.keyword"
                    />
                    <div
                        class="input-group-append"
                        v-if="modals.favorites.search.keyword.length > 0"
                    >
                        <button
                            class="btn btn-secondary"
                            type="button"
                            @click="modals.favorites.search.keyword = ''"
                        >
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </button>
                    </div>
                </div>
                <div
                    v-for="(item, index) in stations.filter((item) => {
                        return item.name
                            .toLowerCase()
                            .includes(
                                modals.favorites.search.keyword.toLowerCase()
                            )
                    })"
                    :key="'c-' + index"
                    class="
                        d-flex
                        shadow-sm
                        bg-white
                        p-2
                        my-2
                        text-center
                        align-items-center
                    "
                    :class="{
                        'border border-primary border-2':
                            modals.favorites.selected.find(
                                (s) => s.id == item.id
                            ),
                    }"
                    @click="toggleFavorites(item)"
                >
                    <div class="text-left">
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                item.slug +
                                '.png'
                            "
                            style="max-width: 40px"
                        />
                    </div>
                    <div class="ml-3">
                        <strong class="mb-0">{{ item.name }}</strong>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="modals.station.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="light"
            @hidden="onStationModalHidden"
        >
            <template #modal-header>
                <div
                    class="
                        w-100
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="modals.station.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                    <b-dropdown
                        dropleft
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <font-awesome-icon
                                class="text-dark"
                                :icon="['fas', 'ellipsis-v']"
                            />
                        </template>

                        <b-dropdown-item
                            v-if="modals.station.selected.url === null"
                            @click="
                                confirm(
                                    'Sender-URL entfernen',
                                    'Möchtest du die Sender-URL wirklich entfernen?',
                                    removeStationUrl
                                )
                            "
                        >
                            Sender-URL entfernen
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="
                                confirm(
                                    'Sender löschen',
                                    'Möchtest du den Sender aus deinen Lieblingssendern entfernen?',
                                    removeFavorite
                                )
                            "
                        >
                            Nicht mehr folgen
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>
            <div
                class="
                    rounded
                    shadow-sm
                    bg-white
                    mb-4
                    p-1
                    text-center
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                "
            >
                <img
                    :src="
                        'images/senderlogos/live_ic_tab_' +
                        modals.station.selected.slug +
                        '.png'
                    "
                    style="max-width: 100px"
                />

                <div
                    style="
                        width: 80px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    "
                >
                    <strong></strong>
                </div>
            </div>
            <button
                type="button"
                class="btn btn-primary btn-block mb-0"
                @click="startBroadcast(modals.station.selected)"
            >
                {{ modals.station.selected.name }} starten
            </button>
            <div v-if="flattenedProgram.length">
                <hr />
                <h5 class="page-title my-0">Was läuft jetzt</h5>
                <div
                    v-for="(item, index) in flattenedProgram.slice(
                        0,
                        modals.station.slice
                    )"
                    :key="'d-' + index"
                >
                    <broadcast
                        :layout="BROADCAST_LAYOUT_PROGRAM"
                        :item="item"
                        :c="item.c"
                    />
                </div>
                <div class="text-center w-100 py-1">
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-block"
                        @click="modals.station.slice = 100"
                        v-if="modals.station.slice == 3"
                    >
                        Alle anzeigen
                    </button>
                </div>

                <div class="text-center w-100 py-1">
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-block"
                        @click="modals.station.slice = 3"
                        v-if="modals.station.slice == 100"
                    >
                        Programm einklappen
                    </button>
                </div>
                <hr />
                <h5 class="page-title my-0">Was läuft heute Abend</h5>
                <div v-for="(item, index) in primeTime" :key="'e-' + index">
                    <broadcast
                        :layout="BROADCAST_LAYOUT_PROGRAM"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="modals.series.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="white"
            @hidden="onStationModalHidden"
        >
            <template #modal-header>
                <div
                    class="
                        w-100
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="modals.series.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                    <b-dropdown
                        dropleft
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <font-awesome-icon
                                class="text-dark"
                                :icon="['fas', 'ellipsis-v']"
                            />
                        </template>
                        <b-dropdown-item
                            @click="
                                confirm(
                                    'Sendung löschen',
                                    'Möchtest du die Sendung aus deinen Sendungen entfernen?',
                                    popSeries
                                )
                            "
                        >
                            Nicht mehr folgen
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>
            <div>
                <div v-if="!modals.series.loading">
                    <div class="d-flex align-items-center">
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                activeSeries.station_slug +
                                '.png'
                            "
                            style="max-width: 75px"
                        />

                        <div class="p-2">
                            <span class="text-muted">{{
                                activeSeries.station_name
                            }}</span>
                            <h5 class="font-weight-bold mb-0">
                                {{ activeSeries.series_name }}
                            </h5>

                            <div
                                v-if="activeSeries.series_rating_count !== null"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'star']"
                                    v-for="n in Math.ceil(stars)"
                                    :key="'aacc' + n"
                                />
                                <font-awesome-icon
                                    :icon="['far', 'star']"
                                    v-for="n in 5 - Math.ceil(stars)"
                                    :key="'aabb' + n"
                                />

                                <span class="ml-2"
                                    >{{
                                        activeSeries.series_rating_count
                                    }}
                                    Bewertungen</span
                                >
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="text-primary text-center font-weight-bold">
                            <font-awesome-icon :icon="['fas', 'heart']" />
                            Sendung wurde gemerkt
                        </div>
                    </div>
                    <hr />
                    <div
                        v-if="
                            activeSeries.broadcasts &&
                            activeSeries.broadcasts.length
                        "
                    >
                        <h5 class="page-title mb-0">
                            Ausstrahlungen der nächsten 14 Tage
                        </h5>
                        <div class="mt-2">
                            <div
                                class="d-inline"
                                v-for="(
                                    item, index
                                ) in activeSeries.time_groups"
                                :key="'aaa-' + index"
                            >
                                <button
                                    v-if="
                                        activeUserSeriesWhitelist.includes(
                                            item.value
                                        )
                                    "
                                    style="width: 85px"
                                    class="btn btn-sm btn-primary m-1"
                                    @click="popUserSeriesWhitelist(item)"
                                >
                                    {{ item.value }}
                                    <font-awesome-icon
                                        v-if="!item.loading"
                                        :icon="['far', 'bell']"
                                    />
                                    <b-spinner
                                        small
                                        v-else
                                        label="Laden..."
                                    ></b-spinner>
                                </button>
                                <button
                                    v-if="
                                        !activeUserSeriesWhitelist.includes(
                                            item.value
                                        )
                                    "
                                    style="width: 85px"
                                    class="btn btn-sm btn-outline-dark m-1"
                                    @click="pushUserSeriesWhitelist(item)"
                                >
                                    {{ item.value }}
                                    <font-awesome-icon
                                        v-if="!item.loading"
                                        :icon="['far', 'bell-slash']"
                                    />
                                    <b-spinner
                                        small
                                        v-else
                                        label="Laden..."
                                    ></b-spinner>
                                </button>
                            </div>
                        </div>
                        <table class="table table-striped table-sm w-100 my-2">
                            <tbody>
                                <tr
                                    v-for="(
                                        item, index
                                    ) in activeSeries.broadcasts"
                                    :key="'zzzk-' + index"
                                >
                                    <td class="text-left" style="width: 85px">
                                        {{
                                            dayjs(item.start).format(
                                                'DD.MM.YYYY'
                                            )
                                        }}
                                    </td>
                                    <td class="text-left">
                                        {{ dayjs(item.start).format('HH:mm') }}
                                    </td>
                                    <td class="text-center" style="width: 30px">
                                        <font-awesome-icon
                                            :icon="['far', 'bell']"
                                            class="text-primary"
                                            v-if="
                                                activeUserSeriesWhitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                        <font-awesome-icon
                                            :icon="['far', 'bell-slash']"
                                            class="text-dark"
                                            v-if="
                                                !activeUserSeriesWhitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <p class="mb-0 text-muted">
                            Für diese Sendung gibt es keine Ausstrahlungen in
                            den nächsten 14 Tagen
                        </p>
                        <hr />
                        <button
                            type="button"
                            class="btn btn-outline-dark btn-block"
                            @click="
                                confirm(
                                    'Sendung löschen',
                                    'Möchtest du die Sendung aus deinen Sendungen entfernen?',
                                    popSeries
                                )
                            "
                        >
                            Dieser Sendung nicht mehr folgen
                        </button>
                    </div>
                </div>
                <div v-else>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="45%"></b-skeleton>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="modals.add_series.show"
            modal-class="modal-fullscreen"
            scrollable
            body-bg-variant="light"
            @hidden="onAddSeriesModalHidden"
            @ok="syncSeries()"
        >
            <template #modal-header>
                <div>
                    <h5 class="mb-0">Meine Sendungen</h5>
                </div>
            </template>
            <template #modal-footer="{ ok, cancel }">
                <button class="btn btn-secondary" @click="cancel()">
                    Abbrechen
                </button>
                <button class="btn btn-primary" @click="ok()">Speichern</button>
            </template>
            <div v-if="!modals.add_series.hide">
                <div v-if="!modals.add_series.collapsed">
                    <ul class="list-group mt-1">
                        <li
                            class="
                                list-group-item
                                d-flex
                                align-items-center
                                justify-content-between
                                my-1
                                p-2
                                shadow-sm
                            "
                            v-for="(item, index) in modals.add_series.selected"
                            :key="'zzz-' + index"
                        >
                            <div class="d-flex align-items-center">
                                <div class="text-left">
                                    <img
                                        :src="
                                            'images/senderlogos/live_ic_tab_' +
                                            item.station_slug +
                                            '.png'
                                        "
                                        style="max-width: 40px"
                                    />
                                </div>
                                <div class="ml-3">
                                    <strong class="d-block text-gray-dark">
                                        {{ item.series_name }}
                                    </strong>
                                    <span class="text-muted">{{
                                        item.station_name
                                    }}</span>
                                </div>
                            </div>
                            <div>
                                <span
                                    class="px-2 py-1"
                                    @click="toggleSeries(item)"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'trash-alt']"
                                    />
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div
                        class="text-center w-100 py-1 font-weight-bold"
                        v-if="!modals.add_series.editMode"
                    >
                        <button
                            type="button"
                            class="btn btn-block btn-outline-primary"
                            @click="modals.add_series.collapsed = true"
                        >
                            Einklappen
                        </button>
                    </div>
                </div>
                <div v-if="!modals.add_series.editMode">
                    <div
                        class="text-center w-100 py-1 font-weight-bold"
                        v-if="
                            modals.add_series.collapsed &&
                            modals.add_series.selected.length
                        "
                    >
                        <button
                            type="button"
                            class="btn btn-block btn-outline-primary"
                            @click="modals.add_series.collapsed = false"
                        >
                            Meine Sendungen anzeigen ({{
                                modals.add_series.selected.length
                            }})
                        </button>
                    </div>
                    <div v-else class="text-muted">
                        Suche deine Lieblingssendungen
                    </div>

                    <hr />
                </div>
            </div>

            <div v-if="!modals.add_series.editMode" class="input-group my-2">
                <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Sendung suchen..."
                    @click="
                        modals.add_series.collapsed = true
                        modals.add_series.hide = true
                    "
                    @keyup="
                        modals.add_series.search.keyword = $event.target.value
                    "
                    :value="modals.add_series.search.keyword"
                />
                <div
                    class="input-group-append"
                    v-if="modals.add_series.search.keyword.length > 0"
                >
                    <button
                        class="btn btn-secondary"
                        type="button"
                        @click="modals.add_series.search.keyword = ''"
                    >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </div>

            <div v-if="modals.add_series.search.loading">
                <b-spinner class="my-4 mx-2" label="Laden..."></b-spinner>
            </div>
            <div
                class="text-muted"
                v-if="
                    !modals.add_series.search.loading &&
                    modals.add_series.search.keyword.length >= 1
                "
            >
                <span v-if="searchResults.length == 1">
                    Wähle deine Lieblingssendung aus
                </span>
                <span v-if="searchResults.length == 0">
                    Keine Sendung gefunden
                </span>
                <span
                    v-if="
                        searchResults.length >= 2 && searchResults.length < 49
                    "
                >
                    Wähle deine Lieblingssendungen aus
                </span>
            </div>
            <ul
                class="list-group mt-1"
                v-if="!modals.add_series.search.loading"
            >
                <li
                    class="
                        list-group-item
                        d-flex
                        align-items-center
                        justify-content-between
                        my-1
                        p-2
                        shadow-sm
                    "
                    :class="{
                        'border border-primary border-2':
                            modals.add_series.selected.find(
                                (s) => s.series_id == item.series_id
                            ),
                    }"
                    v-for="(item, index) in searchResults"
                    :key="'zzzh-' + index"
                    @click="toggleSeries(item)"
                >
                    <div class="d-flex align-items-center">
                        <div class="text-left">
                            <img
                                :src="
                                    'images/senderlogos/live_ic_tab_' +
                                    item.station_slug +
                                    '.png'
                                "
                                style="max-width: 40px"
                            />
                        </div>
                        <div class="ml-2">
                            <strong class="d-block text-gray-dark">
                                {{ item.series_name }}
                            </strong>
                            <span class="text-muted">{{
                                item.station_name
                            }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </b-modal>
        <b-modal
            v-model="modals.stream_source.show"
            scrollable
            hide-footer
            centered
        >
            <div class="input-group mb-3">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Sender-Url eintippen..."
                    :value="modals.stream_source.selected.url"
                    @keyup="modals.stream_source.dirty = true"
                />
                <div class="input-group-append">
                    <button
                        type="button"
                        class="btn mb-3 btn-success"
                        v-if="modals.stream_source.dirty"
                    >
                        <font-awesome-icon
                            :icon="['far', 'save']"
                            class="text-white"
                        />
                    </button>
                    <button
                        class="btn btn-outline-secondary"
                        type="button"
                        v-else
                    >
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </button>
                </div>
            </div>
            <button type="button" class="btn btn-primary btn-block">
                {{ modals.stream_source.selected.name }} suchen
            </button>
        </b-modal>
        <b-modal v-model="modals.google.show" scrollable hide-footer centered>
            <template #modal-header>
                <div
                    class="
                        w-100
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="modals.google.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            Klicke auf "Sender suchen" und wähle einen passenden Sender aus
            <button
                type="button"
                class="btn btn-primary btn-block mt-2"
                @click="addStation()"
            >
                Sender suchen
            </button>
        </b-modal>
        <b-modal
            v-model="modals.ad.show"
            @hidden="onAdModalHidden()"
            @shown="adCountDownTimer()"
            no-close-on-backdrop
            centered
            modal-class="modal-fullscreen"
            body-class="d-flex align-items-center justify-content-center"
            hide-header
            hide-footer
            no-fade
        >
            <div class="text-center">
                <font-awesome-icon
                    style="font-size: 50px"
                    :icon="['fas', 'ad']"
                />
                <h2>Werbung beginnt in</h2>
                <h2>{{ modals.ad.countDown }}s</h2>
            </div>
        </b-modal>
    </div>
</template>
<script>
import Tv from '../../../views/Tv'
export default {
    ...Tv,
}
</script>
