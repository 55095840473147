window._ = require('lodash')

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './lang'

var App = require('./variants/' +
    process.env.VUE_APP_VARIANT +
    '/App.vue').default

//import './registerServiceWorker'
import axios from 'axios'
import LazyLoadDirective from './directives/LazyLoadDirective'

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.prototype.CACHE_LIFETIME = 3600

Vue.prototype.BROADCAST_LAYOUT_LIVE = 1000
Vue.prototype.BROADCAST_LAYOUT_PROGRAM = 2000
Vue.prototype.BROADCAST_LAYOUT_BOOKMARK = 3000
Vue.prototype.BROADCAST_LAYOUT_SEARCH = 4000
Vue.prototype.BROADCAST_LAYOUT_RECOMMENDATION = 5000
Vue.prototype.BROADCAST_LAYOUT_RECOMMENDATION_FULL = 6000

import router from './router'
import store from './store'
import {
    BVToastPlugin,
    SpinnerPlugin,
    FormCheckboxPlugin,
    FormSelectPlugin,
    ModalPlugin,
    SkeletonPlugin,
    DropdownPlugin,
    ImagePlugin,
    AlertPlugin,
    OverlayPlugin,
} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faAd,
    faExchangeAlt,
    faArrowsAltV,
    faDotCircle,
    faChevronRight,
    faPlusCircle,
    faExclamationTriangle,
    faCheck,
    faTimes,
    faUserCog,
    faCog,
    faTrashAlt,
    faClock,
    faAngleRight,
    faMedal,
    faPlayCircle,
    faStream,
    faBookmark,
    faBroadcastTower,
    faStar as fasStar,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faMinusCircle,
    faSearch,
    faStarHalfAlt,
    faMobileAlt,
    faHandHoldingHeart,
    faGift,
    faUser,
    faTv,
    faCalendarDay,
    faMagic,
    faCloudMoon,
    faPlus,
    faEllipsisV,
    faChevronUp,
    faChevronDown,
    faHeart as fasHeart,
    faPlay,
    faFutbol,
    faMicroscope,
    faSortAlphaDown,
    faFilm,
    faImages,
    faNewspaper,
    faChild,
    faMusic,
    faFish,
    faComments,
    faTheaterMasks,
    faGrinTears,
    faHeartbeat,
    faExpandArrowsAlt,
    faFileVideo,
    faSurprise,
    faCalendarCheck,
    faBars,
    faWifi,
    faBell as fasBell,
    faCrown,
    faAward,
    faGlobeEurope,
    faPen,
    faTable,
    faList,
    faInfoCircle,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'

import {
    faStar as farStar,
    faListAlt,
    faBell,
    faSave,
    faBellSlash,
    faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faAd,
    faExchangeAlt,
    faArrowsAltV,
    faDotCircle,
    faChevronRight,
    faPlusCircle,
    faMinusCircle,
    faExclamationTriangle,
    faCheck,
    faTimes,
    faUserCog,
    faCog,
    faTrashAlt,
    faClock,
    faAngleRight,
    faMedal,
    faPlayCircle,
    faStream,
    faBookmark,
    faBroadcastTower,
    fasStar,
    farStar,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faSearch,
    faStarHalfAlt,
    faMobileAlt,
    faHandHoldingHeart,
    faGift,
    faUser,
    faTv,
    faCalendarDay,
    faListAlt,
    faMagic,
    faCloudMoon,
    faPlus,
    faEllipsisV,
    faBell,
    faSave,
    faBellSlash,
    faChevronUp,
    faChevronDown,
    fasHeart,
    farHeart,
    faPlay,
    faFutbol,
    faMicroscope,
    faSortAlphaDown,
    faFilm,
    faImages,
    faNewspaper,
    faChild,
    faMusic,
    faFish,
    faComments,
    faTheaterMasks,
    faGrinTears,
    faHeartbeat,
    faExpandArrowsAlt,
    faFileVideo,
    faSurprise,
    faCalendarCheck,
    faBars,
    faPen,
    faBell,
    faWifi,
    fasBell,
    faCrown,
    faAward,
    faGlobeEurope,
    faTable,
    faList,
    faNewspaper,
    faInfoCircle,
    faUsers
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'dayjs/locale/de'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'

dayjs.extend(isBetween)
dayjs.locale('de')
window.dayjs = dayjs

Vue.directive('lazyload', LazyLoadDirective)

Vue.use(BVToastPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ModalPlugin)
Vue.use(SkeletonPlugin)
Vue.use(DropdownPlugin)
Vue.use(ImagePlugin)
Vue.use(AlertPlugin)
Vue.use(OverlayPlugin)

Vue.use(VueI18n)

Vue.mixin({
    methods: {
        startJoyn(item, source) {
            // START: 10% -> GAGA
            //const random = Math.random() // 15. Dez 2023 -> alles von ca ads auf awin
            //let url = `https://www.tabletmessenger.com/ads/joyn.php?slug=${item.slug}&source=${source}`;
            let url = `https://gaga-tv-app.de/ads/joyn.php?slug=${item.slug}&source=${source}`
            /*if (random < 0.8) {
                url = `https://gaga-tv-app.de/ads/joyn.php?slug=${item.slug}&source=${source}`;
            }*/
            // END

            // only for gagatv app
            if (source.startsWith('gagatv_')) {
                url = `https://gaga-tv-app.de/ads/joyn.php?slug=${item.slug}&source=${source}`
            }
            this.nativeBridge('startTV', {
                slug: 'joyn',
                name: 'JOYN',
                image: 'joyn',
                url,
                image_url:
                    'https://v29.live-tv-programm.com/images/senderlogos/live_ic_tab_joyn.png',
                stream_url: null,
                user_agent:
                    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36',
                gecko: false,
                is_custom_url: false,
                element_actions: null,
                js: `
                function waitForElm(selector) {
                    return new Promise(resolve => {
                        if (document.querySelector(selector)) {
                            return resolve(document.querySelector(selector));
                        }
                        const observer = new MutationObserver(mutations => {
                            if (document.querySelector(selector)) {
                                resolve(document.querySelector(selector));
                                observer.disconnect();
                            }
                        });
                        observer.observe(document.body, {
                            childList: true,
                            subtree: true
                        });
                    });
                }                
                function keyExistsInLs(key, ttl = 10 * 1000) {
                    const prefix = 'JOYN_';
                    if(!localStorage.getItem(prefix+key)) {
                        localStorage.setItem(prefix+key, (new Date).getTime());
                        return false;
                    }
                    if((new Date()).getTime()-localStorage.getItem(prefix+key) > ttl) {
                        localStorage.setItem(prefix+key, (new Date).getTime());
                        return false;
                    }
                    return true;
                }
                function ev(key, meta = {}) {
                    const apiToken = '${localStorage.api_token}';
                    fetch('https://api.live-tv-programm.com/activity/joyn/'+key+'?api_token='+apiToken, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({item: '${item.slug}', source: '${source}', sender: meta.sender, runtime: meta.runtime, year: meta.year})
                    });
                }
                if(document.location.href.includes('www.joyn.de/filme/') || document.location.href.includes('www.joyn.de/serien/')) {
                     if(!keyExistsInLs('click')) {
                        ev('click');
                    }
                    if(!keyExistsInLs('visit')) {
                        ev('visit');
                    }
                    if(document.getElementById("format-tabpanel-trailer")) {
                        document.getElementById("format-tabpanel-trailer").style.display = "none";
                    }
                    waitForElm('.Button_Button__qO_62.ButtonResume_ButtonResumeOriginal__Yx4MS.Button_Button--solid__xiV61').then((elem) => {
                        if(!keyExistsInLs('button_mutation_ready')) {

                            let runtime = null;
                            let sender = null;
                            let year = null;
                            const senderElem = document.querySelector("a.DetailsContent_DetailsContent__SenderLink__4pAjb");
                            const runtimeElem = document.querySelector(".MetaInfo_MetaInfo__ItemText__7GcvJ");
                            const pElems = Array.from(document.querySelectorAll(".DetailsContent_DetailsContent__Row__jcFV8")).filter((item) => {
                                return item.querySelector("div").innerText === 'Produktion:';
                            });
                            if(pElems.length) {
                                year = pElems[0].querySelector("span").innerText;
                            }
                            if(senderElem && runtimeElem) {
                                runtime = parseInt(runtimeElem.textContent.replace("Min.", "").trim());
                                s = senderElem.href.split("/");
                                if(s.length > 1) {
                                    sender = s[s.length-1];
                                }
                            }

                            const userTraits = JSON.parse(localStorage.getItem('ajs_user_traits'));
                            if(userTraits && userTraits.user_status == 'logged in') {
                                ev('logged_in');
                            } else {
                                ev('logged_out');
                            }
                            var buttonState = 'undef_' + elem.textContent;
                            if(elem.textContent.toLowerCase().includes('ansehen') || elem.textContent.toLowerCase().includes('weiterschauen')) {
                                buttonState = 'watch_now';
                            }

                            if(elem.textContent.toLowerCase().includes('registrieren')) {
                                buttonState = 'register_now';
                            }
                            ev(buttonState, {runtime, sender, year});
                            elem.addEventListener('click', e => {
                                if(buttonState == 'watch_now') {
                                    ev('watch_now_clicked');
                                } else {
                                    ev('register_now_clicked');
                                }
                            });
                        }
                    });
                }
                if(document.location.href.includes('signin.7pass.de')) {
                    if(!keyExistsInLs('register_visit')) {
                        ev('register_visit');
                    }
                    waitForElm('form button[type="submit"]').then((elem) => {
                        if(elem.textContent.trim().toLowerCase() === 'speichern' && !keyExistsInLs('button_register_complete_mutation_ready')) {
                            elem.addEventListener('click', e => {
                                ev('register_complete');
                            });
                        }
                    });
                }
                `,
                clear_cookies: true,
                video_player: null,
                tracking_id: null,
                show_ad: false,
                show_premium_hint: false,
            })
        },
        nativeBridge(fn, params) {
            try {
                if (typeof NativeInterface !== 'undefined') {
                    if (typeof params !== 'undefined') {
                        if (typeof params === 'object') {
                            if (fn == 'openUrl') {
                                /* global NativeInterface */
                                return NativeInterface[fn](
                                    params.url,
                                    params.mode
                                )
                            } else {
                                return NativeInterface[fn](
                                    JSON.stringify(params)
                                )
                            }
                        } else {
                            return NativeInterface[fn](params)
                        }
                    } else {
                        return NativeInterface[fn]()
                    }
                } else {
                    console.log('NativeInterface not supported.')
                    console.log('NativeInterface:', fn, params)
                }
            } catch (e) {
                console.error(e)
            }
        },

        handleAxiosError: _.debounce(function (e) {
            console.error(e)
            let errorTitle = ''
            let errorDescription = ''
            if (e.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                //console.log(e.response.data)
                //console.log(e.response.status)
                //console.log("HEADER", e.response.headers)
                errorTitle = 'HTTP-Fehler'
                errorDescription =
                    'Der Server antwortet mit folgendem Status: ' +
                    e.response.status

                axios.post('https://log.hlogo.de/', {
                    key: 'uk9hPTeXKa9Gk_4',
                    data: {
                        token: this.$store.state.auth.token,
                        message: e.response.status,
                        app_version: localStorage.getItem('app_version'),
                    },
                })
            } else if (e.request) {
                /*
                 * The request was made but no response was received, `e.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                //console.log(e.request)
                errorTitle = 'Internetprobleme'
                errorDescription = 'Bitte Internet einschalten'
                axios.post('https://log.hlogo.de/', {
                    key: 'uk9hPTeXKa9Gk_4',
                    data: {
                        token: this.$store.state.auth.token,
                        message: e.message,
                        app_version: localStorage.getItem('app_version'),
                    },
                })
            } else {
                // Something happened in setting up the request and triggered an e
                //console.log('Error', e.message)
                errorTitle = 'Unbekannter Fehler'
                errorDescription = e.message
                axios.post('https://log.hlogo.de/', {
                    key: 'uk9hPTeXKa9Gk_4',
                    data: {
                        token: this.$store.state.auth.token,
                        message: e.message,
                    },
                })
            }
            //console.log("config", e.config)

            const h = this.$createElement

            // Create the message
            const vNodesMsg = h('p', { class: ['mb-0'] }, [
                h('strong', {}, errorTitle),
                h('div', {}, errorDescription),
                //h("div", {}, "URL: " + e.config.url),
                //h("div", {}, "Methode: " + e.config.method)
            ])

            // Create the title
            const vNodesTitle = h(
                'div',
                { class: ['d-flex', 'flex-grow-1', 'align-items-center'] },
                [
                    h('font-awesome-icon', {
                        class: ['text-danger', 'mr-2'],
                        props: { icon: 'exclamation-triangle' },
                    }),
                    h('strong', { class: 'mr-2' }, 'Problem aufgetreten'),
                    //h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
                ]
            )

            this.$bvToast.toast([vNodesMsg], {
                toaster: 'b-toaster-bottom-right',
                title: [vNodesTitle],
                //noAutoHide: true
            })
        }, 5000),
    },
})

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})

new Vue({
    el: '#app',
    render: (h) => h(App),
    components: { App },
    router,
    store,
    i18n,
})

/*

                    if(!keyExistsInLs('autostart_confirm')) {
                        if(confirm('Möchtest du den Stream jetzt 
starten?')) {
                            localStorage.setItem('autostart', true);
                        }
                    }

                    */
