<template>
    <div>
        <b-modal
            @ok="ok"
            @hidden="$emit('input', false)"
            title="Werbung entfernen"
            v-model="modal.show"
            header-bg-variant="white"
            centered
            modal-class="modal-fullscreen"
            body-class=""
            ok-only
            cancel-title="Abbrechen"
        >
            <template #modal-header>
                <div class="text-center rounded w-100 p-1 h5 font-weight-bold">
                    <font-awesome-icon
                        class="display-4 mb-3 text-primary"
                        :icon="['fas', 'crown']"
                    />
                    <br />
                    LIVE TV Pro
                </div>
            </template>
            <div style="font-size: 110%">
                <p>
                    Jetzt mit einer <strong>einmaligen Zahlung</strong> die
                    Werbung in der App dauerhaft entfernen.
                </p>
                <ul>
                    <li>In-App-Werbung<sup>1</sup> dauerhaft entfernen</li>
                    <li>einmalige Zahlung - <strong>kein Abo</strong></li>
                    <li>Weiterentwicklung der App unterstützen</li>
                    <li>nur <strong>einmalig 4,99€</strong></li>
                </ul>
            </div>
            <template #modal-footer="{ ok, cancel }">
                <button class="btn btn-block btn-primary btn-lg" @click="ok()">
                    Jetzt Werbung entfernen
                </button>
                <button
                    class="btn btn-block text-dark btn-link"
                    @click="cancel()"
                >
                    Abbrechen
                </button>
                <div class="w-100 text-left small">
                    <sup>1</sup>Popups und Banner innerhalb der App werden
                    dauerhaft entfernt. Auf die Werbung innerhalb eines Senders/
                    Streams haben wir leider keinen Einfluss.
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            modal: {
                show: false,
                selected: null,
            },
        }
    },
    watch: {
        value: function (n) {
            this.modal.show = n
        },
    },
    methods: {
        ok() {
            this.nativeBridge('openPlayStore', 'live.tv.app.pro')
            localStorage.setItem('banner-payment-hide', true)
        },
    },
    created() {
        this.modal.show = this.value
    },
}
</script>
